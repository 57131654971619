import React, { useContext, useEffect, useRef, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import 'firebase/firestore';
import 'firebase/auth';
import Phase from './Phase/Phase';
import ModalTyping from '../../../components/ModalTyping';
import chargement from '../../../assets/chargement.png';
import editIcon from '../../../assets/editIcon.svg';
import { generateUniqueFirestoreId, joinPaths } from '../../../firebase/utils';
import AgencyContext from '../../../contexts/agencyContext';
import ProgressionAppIcon from '../../../assets/ProgressionAppIcon.png';
import { useTranslation } from 'react-i18next';
import './Progression.scss';
import ModalModifGlobalProgression from './Modaux/ModalModifGlobalProgression';
import { auth, firestore } from '../../../firebase/config';
import { NotificationSource, sendNotificationToAll } from '../Notifications/utils';
import { createdByConstructor } from '../utils';
import { useProjectContext } from '../../../contexts/projectContext';
import AuthDataContext from '../../../contexts/authDataContext';
import TypeContext from '../../../contexts/typeContext';
import PhaseReorganize from './Phase/PhaseReorganize';
import { Paths } from '../../../firebase/paths';
import { ProgressionStatus } from './utils';
import Colors from '../../../useful/Colors';
import { useClientsContext } from '../../../contexts/clientsContext';
import ProgressionHeader from './Header/ProgressionHeader';
import mixpanel from 'mixpanel-browser';
import { usePagePermissions } from '../../../contexts/pagePermissions';

export default function Progression({ isModeLecture }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const authData = useContext(AuthDataContext);
	const agencyId = useContext(AgencyContext);
	const [clients] = useClientsContext();
	const [project] = useProjectContext();
	const [pagePermissions] = usePagePermissions();

	const projectId = project?.id;
	const canModify =
		type === 'collaborators' &&
		!clients?.some((item) => item.id === uid) &&
		!isModeLecture &&
		pagePermissions.progression === 'all';
	const canSee =
		type === 'clients' ||
		clients?.map((item) => item.id).includes(agencyId) ||
		pagePermissions.progression !== 'none';

	const [phases, setPhases] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);
	const [globalProgression, setGlobalProgression] = useState(0);
	const [isReorganizeMode, setIsReorganizeMode] = useState(false);
	const [allTags, setAllTags] = useState([t('translation.allWord')]);
	const [selectedTags, setSelectedTags] = useState([]);

	const [modalModifProgression, setModalModifProgression] = useState(false);
	const [modalNewPhase, setModalNewPhase] = useState(false);

	const bottomRef = useRef();

	useEffect(() => {
		if (project && projectId) {
			setGlobalProgression(project.progression);

			const subscriber = firestore
				.collection(joinPaths(Paths.PROJECTS, projectId, Paths.PROGRESSION))
				.orderBy('date')
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const phases = [];
						const tags = [];
						querySnapshot.forEach((documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								const data = { ...documentSnapshot.data(), id: documentSnapshot.id };
								if (data.tags) {
									data.tags.forEach((tag) => {
										if (!tags.includes(tag)) {
											tags.push(tag);
										}
									});
								}

								switch (data.type) {
									case 'phase': {
										const index = phases.findIndex((item) => item.id === data.id);
										if (index === -1) {
											phases.push({ ...data, stepList: [] });
										} else {
											phases[index] = { ...phases[index], ...data };
										}
										break;
									}
									case 'step': {
										const index = phases.findIndex((item) => item.id === data.phaseId);
										if (index === -1) {
											phases.push({ id: data.phaseId, stepList: [data] });
										} else {
											const stepList = phases[index].stepList ? phases[index].stepList : [];
											const stepIndex = stepList.findIndex((item) => item.id === data.id);
											if (stepIndex === -1) {
												stepList.push(data);
											} else {
												stepList[stepIndex] = { ...stepList[stepIndex], ...data };
											}
											phases[index] = { ...phases[index], stepList };
										}
										break;
									}
									case 'subStep': {
										const phaseIndex = phases.findIndex((item) => item.id === data.phaseId);
										if (phaseIndex === -1) {
											phases.push({
												id: data.phaseId,
												stepList: [{ id: data.stepId, subStepList: [data] }],
											});
										} else {
											const stepList = phases[phaseIndex].stepList
												? phases[phaseIndex].stepList
												: [];
											const stepIndex = stepList.findIndex((item) => item.id === data.stepId);
											if (stepIndex === -1) {
												stepList.push({ id: data.stepId, subSteps: [data] });
											} else {
												const subStepList = stepList[stepIndex].subStepList
													? stepList[stepIndex].subStepList
													: [];
												const subStepIndex = subStepList.findIndex(
													(item) => item.id === data.id
												);
												if (subStepIndex === -1) {
													subStepList.push(data);
												} else {
													subStepList[subStepIndex] = {
														...subStepList[subStepIndex],
														...data,
													};
												}
												stepList[stepIndex] = { ...stepList[stepIndex], subStepList };
											}
											phases[phaseIndex] = { ...phases[phaseIndex], stepList };
										}
										break;
									}
								}
							}
						});
						setPhases(
							phases
								.map((phase) => ({
									...phase,
									stepList: phase.stepList?.sort((a, b) => a.date?.localeCompare(b.date)),
								}))
								.sort((a, b) => a.date?.localeCompare(b.date))
						);
						setAllTags(tags.sort((a, b) => a.localeCompare(b)));
						setDataLoading(false);
					}
				});
			return () => {
				subscriber();
			};
		}
	}, [project, projectId]);

	const newPhase = async (text) => {
		const id = generateUniqueFirestoreId();
		const data = {
			notes: '',
			name: text ? text : t('progression.new_phase'),
			startAt: null,
			endAt: null,
			date: new Date().toISOString(),
			id,
			agency: agencyId ? agencyId : 'clients',
			uid,
			type: 'phase',
		};
		await firestore.doc(`projects/${projectId}/progression/${id}`).set(data);
		bottomRef.current?.scrollIntoView({ behavior: 'smooth' });

		mixpanel.track('Phase Created', {
			'Project ID': projectId,
			Page: 'progression',
		});
	};

	const changeGlobalProgression = async (e) => {
		e.preventDefault();
		setModalModifProgression(false);

		await firestore.doc(`projects/${projectId}`).update({ progression: globalProgression });

		sendNotificationToAll(
			createdByConstructor(uid, type, agencyId),
			projectId,
			NotificationSource.PROGRESSION,
			{ ...project, progression: globalProgression },
			[],
			authData?.surname + ' ' + authData?.name,
			project?.name,
			project?.imgUrl
		);
	};

	return (
		<div className={'progressionPage'}>
			{!canSee && (
				<div className={'blurDiv'} style={{ zIndex: 100 }}>
					<div className={'widgetNoAccess'}>
						<p className={'firstLine'}>{t('translation.noAccessToThisPage')}</p>
						<p className={'secondLine'}>{t('translation.useOpusForYourProjectsProgression')}</p>
					</div>
				</div>
			)}

			<ProgressionHeader
				canModify={canModify}
				isReorganizeMode={isReorganizeMode}
				phases={phases}
				setIsReorganizeMode={setIsReorganizeMode}
				setModalNewPhase={setModalNewPhase}
				allTags={allTags}
				selectedTags={selectedTags}
				setSelectedTags={setSelectedTags}
			/>

			<div
				onClick={() => setModalModifProgression(canModify)}
				className={canModify ? 'containerGlobalPercentage hover' : 'containerGlobalPercentage'}>
				<p>{globalProgression ? globalProgression : 0} %</p>

				{canModify && <img src={editIcon} alt="edit" />}
			</div>

			<div className={'progressionGlobalContainer'}>
				<div style={{ display: 'flex' }}>
					<div className={'progressionPhaseCountContainer'}>
						<p>
							<span style={{ color: '#000' }}>{t('progression_page.phase')} </span>
							{phases.filter((item) => item.status === ProgressionStatus.COMPLETED).length} /{' '}
							{phases.length}
						</p>
					</div>
				</div>

				<div className={'progressionGlobalBackBar'}>
					<div
						className={'progressionGlobalFrontBar'}
						style={{ width: `${(parseInt(globalProgression) ?? 0) + 1}%` }}
					/>
					<div
						className={'progressionGlobalFrontBar'}
						style={{
							width: `${parseInt(globalProgression) ?? 0}%`,
							backgroundColor: Colors.PROGRESSION_GREEN,
							marginTop: -5,
						}}
					/>
				</div>
			</div>

			{!dataLoading ? (
				phases.length > 0 ? (
					<div className={'progressionPhasesContainer'}>
						<div className={'phases'}>
							{phases
								.filter(
									(phase) =>
										isReorganizeMode ||
										selectedTags?.includes(t('translation.allWord')) ||
										phase.tags?.some((tag) => selectedTags?.includes(tag))
								)
								.map((item, index) =>
									isReorganizeMode ? (
										<PhaseReorganize
											key={index}
											phaseData={item}
											setPhases={setPhases}
											indexPhase={index}
										/>
									) : (
										<Phase
											key={index}
											phaseData={item}
											indexPhase={phases?.findIndex((phase) => phase.id === item.id)}
											setPhases={setPhases}
											canModify={canModify}
											allTags={allTags}
											setSelectedTags={setSelectedTags}
										/>
									)
								)}
						</div>
						<div ref={bottomRef} className={'progressionBottomRef'}>
							<p style={{ color: 'transparent' }}>Louis</p>
						</div>
					</div>
				) : (
					<div className={'progressionPhasesContainer'}>
						<div className={'progressionPlaceholder'}>
							<img src={ProgressionAppIcon} alt="" className={'imgAucunePhase'} />
							<p className={'title'}>{t('progression_page.placeholder_progression_1')}</p>
							<p className={'subtitle'}>{t('progression_page.placeholder_progression_2')}</p>
						</div>
					</div>
				)
			) : (
				<div className={'chargementContainer'}>
					<img src={chargement} alt="" className={'chargement'} />
				</div>
			)}

			<ModalModifGlobalProgression
				modalModifGlobalProgression={modalModifProgression}
				setModalModifGlobalProgression={setModalModifProgression}
				globalProgression={globalProgression}
				setGlobalProgression={setGlobalProgression}
				changeGlobalProgression={changeGlobalProgression}
			/>

			<ModalTyping
				modalTyping={modalNewPhase}
				setModalTyping={setModalNewPhase}
				title={t('progression_page.phase_name')}
				func={newPhase}
				placeholder={t('progression_page.phase_name')}
			/>
		</div>
	);
}

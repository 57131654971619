import pdfIcon from '../../../assets/pdfIcon.svg';
import textIcon from '../../../assets/textIcon.svg';
import csvIcon from '../../../assets/csvIcon.svg';
import folder from '../../../assets/folder.png';
import emptyFolder from '../../../assets/emptyFolder.png';
import fileIcon from '../../../assets/fileIcon.svg';
import videoIcon from '../../../assets/videoIcon.svg';
import pictureIcon from '../../../assets/pictureIcon.png';
import { firestore } from '../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../firebase/paths';
import { arrayRemove, arrayUnion } from 'firebase/firestore';

/**
 * Return a formatted string of the given bytes
 * @param {number} bytes
 * @param {number} decimals
 * @returns {string}
 */
export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Octets';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	let formattedNumber = (bytes / Math.pow(k, i)).toFixed(dm);

	// Remplacer le point par une virgule et supprimer les zéros inutiles
	formattedNumber = formattedNumber.replace('.', ',').replace(/,?0+$/, '');

	return formattedNumber + ' ' + sizes[i];
}

/**
 * Return the icon file corresponding to the given type
 * @param {string} type
 * @returns {string}
 */
export function chooseIconFile(type) {
	if (type === 'application/pdf') {
		return pdfIcon;
	} else if (type?.includes('image') || type?.includes('video') || type?.includes('heic') || type?.includes('heif')) {
		return pictureIcon;
	} else if (type?.includes('video')) {
		return videoIcon;
	} else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
		return textIcon;
	} else if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
		return csvIcon;
	} else if (type === 'folder') {
		return folder;
	} else if (type === 'emptyFolder') {
		return emptyFolder;
	} else {
		return fileIcon;
	}
}

export async function modifyPermissionsFile(
	uid,
	type,
	agencyId,
	projectId,
	setFiles,
	file,
	permissionIds,
	clientPermission,
	permissionsReadOnly,
	clientPermissionReadOnly,
	isInsideFolder,
	deletePermissions
) {
	setFiles((prevState) => {
		const nextState = [...prevState];
		const index = nextState.findIndex((f) => f.id === file.id);
		if (index !== -1) {
			if (permissionIds) {
				if (!deletePermissions) {
					nextState[index].permissions = [...nextState[index].permissions, ...permissionIds];
					nextState[index].permissionsReadOnly = [
						...(nextState[index].permissionsReadOnly?.filter(
							(permissionId) => !permissionsReadOnly?.includes(permissionId)
						) ?? []),
						...(permissionsReadOnly ?? []),
					];
				} else {
					nextState[index].permissions = nextState[index].permissions.filter(
						(permissionId) => !permissionIds.includes(permissionId)
					);
					nextState[index].permissionsReadOnly =
						nextState[index].permissionsReadOnly?.filter(
							(permissionId) => !permissionIds.includes(permissionId)
						) ?? [];
				}
			}
			if (clientPermission) {
				nextState[index].clientPermission = !deletePermissions;
				nextState[index].clientPermissionReadOnly = clientPermissionReadOnly && !deletePermissions;
			}
		}
		return nextState;
	});

	let batch = firestore.batch();
	let operationCount = 0;
	const MAX_OPERATIONS = 500;

	const commitBatch = async () => {
		await batch.commit();
		batch = firestore.batch();
		operationCount = 0;
	};

	const updateDoc = (docRef, data) => {
		batch.update(docRef, data);
		operationCount++;
		if (operationCount >= MAX_OPERATIONS) {
			return commitBatch();
		}
	};

	if (isInsideFolder) {
		let ref = firestore
			.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
			.where('path', '>=', `${file.path}${file.id}/`)
			.where('path', '<', `${file.path}${file.id}/` + '\uf8ff');
		if (type === 'clients') {
			ref = ref.where('createdBy.userId', '==', uid);
		} else {
			ref = ref.where('createdBy.agencyId', '==', agencyId);
		}
		const querySnapshot = await ref.get();
		for (const doc of querySnapshot.docs) {
			if (doc.exists) {
				const docRef = doc.ref;
				if (permissionIds) {
					await updateDoc(docRef, {
						permissions: !deletePermissions ? arrayUnion(...permissionIds) : arrayRemove(...permissionIds),
						permissionsReadOnly: !deletePermissions
							? arrayUnion(...(permissionsReadOnly ?? []))
							: arrayRemove(...permissionIds),
					});
					if (permissionIds?.filter((id) => !permissionsReadOnly?.includes(id))?.length > 0) {
						await updateDoc(docRef, {
							permissionsReadOnly: arrayRemove(
								...permissionIds?.filter((id) => !permissionsReadOnly?.includes(id))
							),
						});
					}
				}
				if (clientPermission) {
					await updateDoc(docRef, {
						clientPermission: !deletePermissions,
						clientPermissionReadOnly: clientPermissionReadOnly && !deletePermissions,
					});
				}
			}
		}
	}

	if (!deletePermissions) {
		for (const folderId of file.path.split('/').filter((id) => id)) {
			const docRef = firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${folderId}`);
			const folderData = (await docRef.get()).data();
			if (permissionIds) {
				await updateDoc(docRef, {
					permissions: !deletePermissions ? arrayUnion(...permissionIds) : arrayRemove(...permissionIds),
					permissionsReadOnly: !deletePermissions
						? arrayUnion(
								...(permissionsReadOnly?.filter((id) => !folderData?.permissions?.includes(id)) ?? [])
							)
						: arrayRemove(...permissionIds),
				});
				if (permissionIds?.filter((id) => !permissionsReadOnly?.includes(id))?.length > 0) {
					await updateDoc(docRef, {
						permissionsReadOnly: arrayRemove(
							...permissionIds?.filter((id) => !permissionsReadOnly?.includes(id))
						),
					});
				}
			}
			if (clientPermission) {
				await updateDoc(docRef, {
					clientPermission: !deletePermissions,
					clientPermissionReadOnly:
						clientPermissionReadOnly && !deletePermissions && !folderData?.clientPermission,
				});
			}
		}
	}

	const fileDocRef = firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`);
	if (permissionIds) {
		await updateDoc(fileDocRef, {
			permissions: !deletePermissions ? arrayUnion(...permissionIds) : arrayRemove(...permissionIds),
			permissionsReadOnly: !deletePermissions
				? arrayUnion(...(permissionsReadOnly ?? []))
				: arrayRemove(...permissionIds),
		});
		if (permissionIds?.filter((id) => !permissionsReadOnly?.includes(id))?.length > 0) {
			await updateDoc(fileDocRef, {
				permissionsReadOnly: arrayRemove(...permissionIds?.filter((id) => !permissionsReadOnly?.includes(id))),
			});
		}
	}
	if (clientPermission) {
		await updateDoc(fileDocRef, {
			clientPermission: !deletePermissions,
			clientPermissionReadOnly: clientPermissionReadOnly && !deletePermissions,
		});
	}

	// Commit any remaining operations in the batch
	if (operationCount > 0) {
		await commitBatch();
	}
}

export const favoritesId = 'favorites';
export const recentsId = 'recents';
export const trashId = 'trash';

export const optionFolders = [
	{
		id: favoritesId,
		isFolder: true,
		name: 'Favoris',
		path: '/',
		isOptionFolder: true,
	},
	{
		id: recentsId,
		isFolder: true,
		name: 'Récents',
		path: '/',
		isOptionFolder: true,
	},
	{
		id: trashId,
		isFolder: true,
		name: 'Supprimés récemment',
		path: '/',
		isOptionFolder: true,
	},
];

import React, { useContext, useEffect, useState } from 'react';
import './_documents.scss';
import FolderPath from './components/Folder Path/FolderPath';
import { useTranslation } from 'react-i18next';
import DocumentNavigator from './components/Document Navigator/DocumentNavigator';
import DocumentInfos from './components/Document Infos/DocumentInfos';
import ModalPreviewDocument from './components/Modal Preview Document/ModalPreviewDocument';
import { firestore } from '../../../firebase/config';
import { AGENCY_COLLECTION, PROJECT_COLLECTION, USER_COLLECTION } from '../../../firebase/paths';
import AgencyContext from '../../../contexts/agencyContext';
import ModalMoveItem from './components/Document Infos/modaux/ModalMoveItem';
import { optionFolders } from './utils';
import { useLocation } from 'react-router-dom';
import PartnerFilter from './components/PartnerFilter/PartnerFilter';
import ModalPermissions from './components/Modal Permissions/ModalPermissions';
import TypeContext from '../../../contexts/typeContext';
import { getFirstLetters } from '../../../useful/UsefulFunctions';
import mixpanel from 'mixpanel-browser';

export default function Documents({ projectId, isMandataire, isModeLecture }) {
	// General
	const { t } = useTranslation();
	const agencyId = useContext(AgencyContext);
	const type = useContext(TypeContext);
	const location = useLocation();

	// Selection
	const [path, setPath] = useState([]);
	const [startSelectionIndex, setStartSelectionIndex] = useState(0);
	const [selectedFilePath, setSelectedFilePath] = useState('');
	const [selectedFileIds, setSelectedFileIds] = useState([]);

	// Preview
	const [modalPreview, setModalPreview] = useState(false);
	const [previewFile, setPreviewFile] = useState(null);

	// Move Item
	const [modalMoveItem, setModalMoveItem] = useState(false);

	// Data
	const [files, setFiles] = useState([...optionFolders]);
	const [diminutifs, setDiminutifs] = useState({});

	const [filterPartnerId, setFilterPartnerId] = useState(agencyId);

	// Modal set access during import
	const [modalSetAccessDuringImport, setModalSetAccessDuringImport] = useState(false);
	const [uploadedFileIds, setUploadedFileIds] = useState([]);

	useEffect(() => {
		if (location && location.state) {
			if (location.state.path) {
				setPath(location.state.path);
			}
			if (location.state.selectedFileIds) {
				setSelectedFileIds(location.state.selectedFileIds);
			}
		}
	}, [location]);

	useEffect(() => {
		if (projectId) {
			firestore
				.collection(PROJECT_COLLECTION)
				.doc(projectId)
				.collection(USER_COLLECTION)
				.where('type', '==', 'agencies')
				.get()
				.then((snapshot) => {
					const partners = [];
					const diminutifs = {};
					const promises = [];
					snapshot.forEach((doc) => {
						if (doc && doc.exists) {
							if (doc.id !== agencyId) {
								partners.push(doc.id);
								const promise = firestore
									.doc(`${AGENCY_COLLECTION}/${doc.id}`)
									.get()
									.then((doc) => {
										if (doc && doc.exists) {
											diminutifs[doc.id] =
												doc.data().diminutifAgency ?? getFirstLetters(doc.data().name);
										}
									});
								promises.push(promise);
							}
						}
					});

					Promise.all(promises).then(() => {
						setDiminutifs(diminutifs);
					});
				});
		}
	}, [projectId]);

	useEffect(() => {
		setFilterPartnerId(agencyId);
	}, [agencyId]);

	// Listener path
	useEffect(() => {
		if (filterPartnerId === 'vous')
			setFiles((prevState) =>
				prevState.filter((it) => path.reduce((prev, cur) => prev + `${cur}/`, '/').includes(it.path))
			);
	}, [path]);

	const toggleFileSelection = (file, isShiftClick, isCtrlClick) => {
		const index = files.indexOf(file);
		let selectionIndex = startSelectionIndex;

		if (file.path !== selectedFilePath) {
			setSelectedFileIds([]);
			setStartSelectionIndex(index);
			selectionIndex = files.findIndex((it) => it.path === file.path);
		}

		setPath(() => {
			const newState = [];
			file.path.split('/').forEach((item, index) => {
				if (item) {
					newState.push(item);
				}
			});
			return newState;
		});

		if (isShiftClick) {
			const start = Math.min(index, selectionIndex);
			const end = Math.max(index, selectionIndex);
			const newSelectedFileIds = [];
			for (let i = start; i <= end; i++) {
				newSelectedFileIds.push(files[i].id);
			}
			setSelectedFileIds(newSelectedFileIds);
		} else if (isCtrlClick) {
			setSelectedFileIds((prevState) =>
				prevState.includes(file) ? prevState.filter((it) => it !== file.id) : [...prevState, file.id]
			);
			setStartSelectionIndex(index);
		} else {
			if (file.isFolder) {
				setPath((prevState) => [...prevState, file.id]);
				mixpanel.track('Folder Opened', {
					'Project ID': projectId,
					Page: 'files',
				});
			}
			setSelectedFileIds([file.id]);
			setStartSelectionIndex(index);
		}
		setSelectedFilePath(file.path);
	};

	const [alreadyClicked, setAlreadyClicked] = useState(false);
	const handleClickPage = () => {
		if (!alreadyClicked) {
			setAlreadyClicked(true);
			mixpanel.track_pageview({
				Page: 'files',
				'Project ID': projectId,
			});
		}
	};

	return (
		<div className={'documentsPage'} onClick={handleClickPage}>

			<FolderPath
				t={t}
				path={path}
				setPath={setPath}
				files={files}
				setFiles={setFiles}
				setSelectedFileIds={setSelectedFileIds}
			/>

			{!isModeLecture && (
				<PartnerFilter
					projectId={projectId}
					selectedPart={filterPartnerId}
					setSelectedPart={setFilterPartnerId}
					isClientInterface={type === 'clients'}
				/>
			)}

			<div className={'containerContentDocuments'}>
				<DocumentNavigator
					projectId={projectId}
					path={path}
					files={files}
					setFiles={setFiles}
					selectedFileIds={selectedFileIds}
					toggleFileSelection={toggleFileSelection}
					setModalPreview={setModalPreview}
					modalPreview={modalPreview}
					setPreviewFile={setPreviewFile}
					modalMoveItem={modalMoveItem}
					diminutifs={diminutifs}
					filterPartnerId={filterPartnerId}
					setUploadedFileIds={setUploadedFileIds}
					setModalSetAccessDuringImport={setModalSetAccessDuringImport}
					isModeLecture={isModeLecture}
				/>

				{selectedFileIds.length !== 0 &&
					!isModeLecture &&
					selectedFileIds.every((it) => !files.find((file) => file.id === it)?.isOptionFolder) &&
					files.find((file) => file.id === selectedFileIds[0]) && (
						<DocumentInfos
							projectId={projectId}
							selectedFileIds={selectedFileIds}
							setSelectedFileIds={setSelectedFileIds}
							files={files}
							setFiles={setFiles}
							path={path}
							setPath={setPath}
							setModalMoveItem={setModalMoveItem}
							filterPartnerId={filterPartnerId}
							parentFolder={files.find((file) => file.id === path[path.length - 1])}
						/>
					)}
			</div>

			<div style={{zIndex: 10}}>
				{previewFile && (
					<ModalPreviewDocument
						setModalPreview={setModalPreview}
						modalPreview={modalPreview}
						files={files}
						previewFile={previewFile}
						setPreviewFile={setPreviewFile}
						toggleFileSelection={toggleFileSelection}
					/>
				)}
			</div>

			<ModalMoveItem
				setModalMoveItem={setModalMoveItem}
				modalMoveItem={modalMoveItem}
				projectId={projectId}
				filesToMove={files.filter((it) => selectedFileIds.includes(it.id))}
				setSelectedFileIds={setSelectedFileIds}
				setPath={setPath}
			/>

			<ModalPermissions
				modal={modalSetAccessDuringImport}
				setModal={setModalSetAccessDuringImport}
				files={files}
				setFiles={setFiles}
				uploadedFileIds={uploadedFileIds}
				setUploadedFileIds={setUploadedFileIds}
				parentFolder={files.find((file) => file.id === path[path.length - 1])}
			/>

		</div>
	);
}

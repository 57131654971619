import React, { useContext, useEffect, useState } from 'react';
import './_documentItem.scss';
import rightIcon from '../../../../../../assets/rightIcon.svg';
import eye from '../../../../../../assets/eye.svg';
import barreEye from '../../../../../../assets/barreEye.svg';
import whiteEye from '../../../../../../assets/whiteEye.svg';
import whiteBarreEye from '../../../../../../assets/whiteBarreEye.svg';
import rightIconWhite from '../../../../../../assets/rightIconWhite.svg';
import { chooseIconFile } from '../../../utils';
import AgencyContext from '../../../../../../contexts/agencyContext';
import { auth, firestore } from '../../../../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../../firebase/paths';
import TypeContext from '../../../../../../contexts/typeContext';
import { useTranslation } from 'react-i18next';
import { useClientsContext } from '../../../../../../contexts/clientsContext';

export default function DocumentItem({
	index,
	file,
	isSelected,
	isInPath,
	toggleFileSelection,
	setModalPreview,
	setPreviewFile,
	partners,
	diminutifs,
	projectId,
	selectedPart,
}) {
	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);
	const type = useContext(TypeContext);
	const [clients] = useClientsContext();

	const isHisFile = file.createdBy.agencyId === agencyId || file.createdBy.userId === uid;
	const [isEmpty, setIsEmpty] = useState(false);

	useEffect(() => {
		if (projectId && file.path && file.isFolder && clients) {
			let ref = firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
				.where('isFolder', '==', false);
			if (type === 'collaborators' && !clients?.map((item) => item.id).includes(agencyId)) {
				ref = ref.where('permissions', 'array-contains-any', [agencyId, uid]);
			} else {
				ref = ref.where('clientPermission', '==', true);
			}
			const listener = ref
				.where('path', '>=', `${file.path}${file.id}/`)
				.where('path', '<', `${file.path}${file.id}/\uf8ff`)
				.limit(1)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						setIsEmpty(querySnapshot.empty);
					}
				});
			return () => listener();
		}
	}, [projectId, file.path, clients, agencyId, uid]);

	return (
		<div
			className={
				isSelected
					? 'documentItemSelected'
					: isInPath
						? 'documentItemInPath'
						: file.isLoading
							? 'documentItemLoading'
							: 'documentItem'
			}
			onDoubleClick={async () => {
				if (!file.isLoading && !file.isFolder) {
					setModalPreview(true);
					setPreviewFile(file);
				}
			}}
			onClick={async (e) => {
				if (!file.isLoading) {
					toggleFileSelection(file, e.shiftKey, e.ctrlKey || e.metaKey);
					if (isSelected && !file.isFolder) {
						setModalPreview(true);
						setPreviewFile(file);
					}
					if (!file.seenBy.map((item) => item.userId).includes(uid)) {
						await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`).update({
							seenBy: arrayUnion({
								userId: uid,
								seenAt: new Date().toISOString(),
							}),
						});
					}
				}
			}}>
			<div className={file.isFolder ? 'folderItem' : 'fileItem'}>
				<>
					<div style={{ width: 43 }}>
						<img
							style={{
								opacity:
									type === 'clients' || !isHisFile
										? 1
										: selectedPart === 'clients'
											? file.clientPermission
												? 1
												: 0.4
											: file.permissions?.includes(selectedPart)
												? 1
												: 0.4,
							}}
							src={
								file.isFolder
									? isEmpty
										? chooseIconFile('emptyFolder')
										: chooseIconFile('folder')
									: (file.type?.includes('image') ||
										file?.type?.includes('heic') ||
										file?.type?.includes('heif')) &&
										file.size < 3000000
										? file?.url
										: chooseIconFile(file.type)
							}
							alt=""
							className={'folderIcon'}
						/>
					</div>
					<NameComponent
						title={file.name}
						emptyFolder={isEmpty}
						isSelected={isSelected}
						isInvisible={
							type === 'clients' || !isHisFile
								? false
								: selectedPart === 'clients'
									? !file.clientPermission
									: !file.permissions?.includes(selectedPart)
						}
					/>
				</>

				<>
					{isHisFile ? (
						<div>
							{selectedPart !== agencyId && (
								<img
									style={{
										marginRight: file.isFolder ? 0 : 16,
										opacity: isSelected
											? 1
											: selectedPart === 'clients'
												? file.clientPermission
													? 0.7
													: 0.3
												: file.permissions?.includes(selectedPart)
													? 0.7
													: 0.3,
									}}
									src={
										selectedPart === 'clients'
											? file.clientPermission
												? isSelected
													? whiteEye
													: eye
												: isSelected
													? whiteBarreEye
													: barreEye
											: file.permissions?.includes(selectedPart)
												? isSelected
													? whiteEye
													: eye
												: isSelected
													? whiteBarreEye
													: barreEye
									}
									alt=""
									className={'eye'}
								/>
							)}
						</div>
					) : (
						<Diminutif
							isSelected={isSelected}
							isMo={file.createdBy.userType === 'client'}
							diminutif={diminutifs[file.createdBy?.agencyId]}
						/>
					)}
					{file.isFolder && (
						<img
							src={isSelected ? rightIconWhite : rightIcon}
							alt=""
							className={'righticon'}
							style={{ opacity: isSelected ? 1 : null }}
						/>
					)}
				</>
			</div>
		</div>
	);
}

function Diminutif({ isSelected, isMo, diminutif }) {
	return (
		<div className={'diminutif'}>
			<p style={{ color: isSelected ? '#FFF' : isMo ? '#59b75e' : '#4876e0' }}>{isMo ? 'MOA' : diminutif}</p>
		</div>
	);
}

function NameComponent({ title, emptyFolder, isSelected, isInvisible }) {
	const { t } = useTranslation();

	return (
		<div
			style={{
				width: 'calc(100% - 50px)',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'start',
				opacity: isInvisible ? 0.5 : 1,
			}}>
			<p className={'name'} style={{ marginTop: -2 }}>
				{title}{' '}
				<span
					style={{
						fontSize: 13,
						fontWeight: '600',
						opacity: isSelected ? 0.7 : 0.5,
						color: isSelected && '#FFF',
					}}>
					{emptyFolder && t('common.empty')}
				</span>
			</p>
		</div>
	);
}

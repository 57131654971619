import React, { useEffect, useState } from 'react';
import ImageJoinProject from '../../assets/ImageJoinProject.png';
import './_joinProjectPage.scss';
import appartementParis2 from '../../assets/appartementParis2.jpg';
import leftIcon from '../../assets/leftIcon.svg';
import HeaderJoinProject from './components/Header Footer/HeaderJoinProject';
import FooterJoinProject from './components/Header Footer/FooterJoinProject';
import ProjectToJoin from './Steps/ProjectToJoin';
import ProfileStep from './Steps/ProfileStep';
import SecurityProfileStep from './Steps/SecurityProfileStep';
import SelectRoleStep from './Steps/SelectRoleStep';
import ClientTypeChoiceStep from './Steps/ClientTypeChoiceStep';
import ChooseACompanyStep from './Steps/ChooseACompanyStep';
import InfosEntrepriseStep from './Steps/InfosEntrepriseStep';
import AccessProjectFinalStep from './Steps/AccessProjectFinalStep';
import ClientCompanyOrIndividual from './Steps/ClientCompanyOrIndividual';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth, firestore, functions } from '../../firebase/config';
import { AGENCY_COLLECTION, PROJECT_COLLECTION, USER_COLLECTION } from '../../firebase/paths';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, onAuthStateChanged } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { createdByConstructor } from '../Fiche Projet/utils';
import { generateUniqueFirestoreId } from '../../firebase/utils';
import O_Opus from '../../assets/O_Opus.png';
import { sendNotificationToCollaborators } from '../Fiche Projet/Notifications/utils';
import QRCode from 'react-qr-code';
import { httpsCallable } from 'firebase/functions';

export const STEPS = {
	projectToJoin: 'projectToJoin',
	yourProfile: 'yourProfile',
	securityProfile: 'securityProfile',
	selectRole: 'selectRole',
	clientChoice: 'clientChoice',
	clientCompanyOrIndividual: 'clientCompanyOrIndividual',
	infosEntrepriseClient: 'infosEntrepriseClient',
	chooseEntreprise: 'chooseEntreprise',
	infosEntreprise: 'infosEntreprise',
	accessProjectLastStep: 'accessProjectLastStep',
};
export const ROLES = {
	moa: 'moa',
	collaborator: 'collaborator',
	entreprise: 'entreprise',
};

export const CLIENT_TYPE = {
	company: 'company',
	individual: 'individual',
};

export default function JoinProject() {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const projectId = searchParams.get('id');
	const userType = searchParams.get('type');
	const navigate = useNavigate();
	const urlComplete = window.location.href;

	// Steps

	const [step, setStep] = useState(STEPS.projectToJoin);

	// Project Infos

	const [dataLoading, setDataLoading] = useState(true);
	const [projectData, setProjectData] = useState({});
	const [projectName, setProjectName] = useState('');
	const [agencyProject, setAgencyProject] = useState('');
	const [diminutifAgencyProject, setDiminutifAgencyProject] = useState('');
	const [projectImg, setProjectImg] = useState(appartementParis2);
	const [projectPercentage, setProjectPercentage] = useState(0);

	// User Infos

	const [name, setName] = useState('');
	const [surname, setSurname] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [emailAddress, setEmailAddress] = useState('');

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [role, setRole] = useState(ROLES.moa);
	const [clientRole, setClientRole] = useState('');
	const [clientType, setClientType] = useState(CLIENT_TYPE.individual);

	const [projectMandataireId, setProjectMandataireId] = useState('');
	const [selectedCid, setSelectedCid] = useState('');

	const [createCompany, setCreateCompany] = useState(true);
	const [companyName, setCompanyName] = useState('');
	const [diminutif, setDiminutif] = useState('');
	const [roleIds, setRoleIds] = useState([]);

	const [companyIds, setCompanyIds] = useState([]);

	// Modaux
	const [alreadyConnected, setAlreadyConnected] = useState(false);

	// Chargement
	const [isLoading, setIsLoading] = useState(false);
	const [isLoading1, setIsLoading1] = useState(false);
	const [isLoading2, setIsLoading2] = useState(false);
	const [isLoading3, setIsLoading3] = useState(false);

	useEffect(() => {
		if (step === STEPS.projectToJoin) {
			if (auth.currentUser) {
				firestore
					.doc(`${PROJECT_COLLECTION}/${projectId}/${USER_COLLECTION}/${auth.currentUser.uid}`)
					.get()
					.then((doc) => {
						if (doc.exists) {
							navigate(`/projets`);
						}
					});
			}
			const listener = onAuthStateChanged(auth, (user) => {
				if (user) {
					setAlreadyConnected(true);
				}
			});
			return () => listener();
		}
	}, [step, auth.currentUser?.uid]);

	useEffect(() => {
		if (projectId) {
			firestore
				.doc(`${PROJECT_COLLECTION}/${projectId}`)
				.get()
				.then((doc) => {
					if (doc.exists) {
						const data = { ...doc.data(), id: doc.id };
						setProjectData(data);
						setProjectName(data.name);
						setProjectImg(data.imgUrl);
						setProjectPercentage(data.progression);
						setProjectMandataireId(data.creator);
						firestore
							.doc(`${AGENCY_COLLECTION}/${data.creator}`)
							.get()
							.then((doc) => {
								if (doc.exists) {
									const data = { ...doc.data(), id: doc.id };
									setAgencyProject(data.name);
									setDiminutifAgencyProject(data.diminutifAgency);
									setDataLoading(false);
								} else {
									alert('Lien invalide (1)'); // TODO: translate
									navigate('/');
								}
							})
							.catch((error) => {
								alert('Lien invalide (2)'); // TODO: translate
								navigate('/');
							});
					} else {
						alert('Lien invalide (3)'); // TODO: translate
						navigate('/');
					}
				})
				.catch((error) => {
					alert('Lien invalide (4)'); // TODO: translate
					navigate('/');
				});
		} else {
			alert('Lien invalide (5)'); // TODO: translate
			navigate('/');
		}
	}, [projectId]);

	useEffect(() => {
		if (projectId && projectMandataireId) {
			const listener = firestore
				.collection(PROJECT_COLLECTION)
				.doc(projectId)
				.collection(USER_COLLECTION)
				.where('type', '==', 'agencies')
				.onSnapshot((querySnapshot) => {
					if (querySnapshot.empty) {
						setCompanyIds([]);
					} else {
						setCompanyIds(
							querySnapshot.docs.map((doc) => doc.id).filter((id) => id !== projectMandataireId)
						);
					}
				});
			return () => listener();
		}
	}, [projectId, projectMandataireId]);

	// Animation for the right part

	const [marginLeftForm, setMarginLeftForm] = useState(0);
	const [opacityForm, setOpacityForm] = useState(1);

	const [count, setCount] = useState(false);
	const [opacity, setOpacity] = useState(0);
	const [marginTop, setMarginTop] = useState(0);
	const [widthLeft, setWidthLeft] = useState(100 + 'vw');
	const [widthSecondPart, setWidthSecondPart] = useState(100 + 'vw');
	const [widthRight, setWidthRight] = useState(0 + 'vw');

	useEffect(() => {
		setTimeout(() => {
			setCount(true);
		}, 500);
	}, []);

	useEffect(() => {
		if (count) {
			setOpacity(1);
		}
	}, [count]);

	useEffect(() => {
		setTimeout(() => {
			if (count) {
				setWidthLeft(50 + 'vw');
				setWidthSecondPart(50 + 'vw');
				setWidthRight(50 + 'vw');
			}
		}, 500);
	}, [count]);

	// Next Step

	function animationNextStep() {
		setOpacity(0);
		setMarginTop(100);
	}

	function handleNextStep(path) {
		const anim = (nextStep) => {
			animationNextStep();
			setTimeout(() => {
				setMarginTop(0);
				setOpacity(1);
				setStep(nextStep);
			}, 800);
		};
		if (path === STEPS.securityProfile) {
			fetchSignInMethodsForEmail(auth, emailAddress).then((task) => {
				if (task.length === 0) {
					anim(path);
				} else {
					window.alert(t('common.email_already_used'));
				}
			});
		} else if (path === STEPS.selectRole) {
			if (userType === ROLES.moa) {
				setRole(ROLES.moa);
				anim(STEPS.clientCompanyOrIndividual);
			} else if (userType === ROLES.collaborator) {
				setRole(ROLES.collaborator);
				anim(STEPS.accessProjectLastStep);
			} else if (userType === ROLES.entreprise) {
				setRole(ROLES.entreprise);
				if (companyIds.length > 0) {
					setStep(STEPS.chooseEntreprise);
				} else {
					setStep(STEPS.infosEntreprise);
				}
			} else {
				anim(path);
			}
		} else {
			anim(path);
		}
	}

	function handleNextStepSelectRole() {
		animationNextStep();
		setTimeout(() => {
			setMarginTop(0);
			setOpacity(1);
			if (role === ROLES.moa) {
				setStep(STEPS.clientCompanyOrIndividual);
			} else if (role === ROLES.entreprise) {
				if (companyIds.length > 0) {
					setStep(STEPS.chooseEntreprise);
				} else {
					setStep(STEPS.infosEntreprise);
				}
			} else if (role === ROLES.collaborator) {
				setStep(STEPS.accessProjectLastStep);
			}
		}, 800);
	}

	function handleNextStepEmployeeStatus() {
		animationNextStep();
		setTimeout(() => {
			setMarginTop(0);
			setOpacity(1);
			setStep(STEPS.accessProjectLastStep);
		}, 800);
	}

	function handleNextStepCompanyOrIndividual() {
		animationNextStep();
		setTimeout(() => {
			setMarginTop(0);
			setOpacity(1);
			if (clientType === CLIENT_TYPE.individual) {
				setStep(STEPS.accessProjectLastStep);
			} else {
				if (companyIds.length > 0) {
					setStep(STEPS.chooseEntreprise);
				} else {
					setStep(STEPS.infosEntrepriseClient);
				}
			}
		}, 800);
	}

	function handleNextStepChooseACompany() {
		animationNextStep();
		setTimeout(() => {
			setMarginTop(0);
			setOpacity(1);
			if (createCompany) {
				if (role === ROLES.moa) {
					setStep(STEPS.infosEntrepriseClient);
				} else {
					setStep(STEPS.infosEntreprise);
				}
			} else {
				setStep(STEPS.accessProjectLastStep);
			}
		}, 800);
	}

	const handleFinish = () => {
		setIsLoading(true);
		setIsLoading1(true);
		createUserWithEmailAndPassword(auth, emailAddress, password)
			.then(async (userCredential) => {
				const uid = userCredential.user.uid;
				const agencyId =
					role === ROLES.collaborator
						? projectMandataireId
						: (role === ROLES.entreprise || clientType === CLIENT_TYPE.company) && !createCompany
							? selectedCid
							: generateUniqueFirestoreId();

				await firestore.doc(`accounts/${uid}`).set(
					role === ROLES.moa && clientType === CLIENT_TYPE.individual
						? {
								uid,
								tokens: [],
								type: 'clients',
							}
						: {
								agency: agencyId,
								uid,
								tokens: [],
								type: 'collaborators',
							}
				);

				setIsLoading2(true);

				let agencyData = {};
				if ((role === ROLES.entreprise || clientType === CLIENT_TYPE.company) && createCompany) {
					await firestore.doc(`accounts/${agencyId}`).set({
						uid: agencyId,
						type: 'agencies',
					});
					agencyData = {
						uid: agencyId,
						date: new Date().toISOString(),
						logoUrl: '',
						diminutifAgency: diminutif,
						name: companyName,
						firstAccount: uid,
					};
					await firestore.doc(`agencies/${agencyId}`).set(agencyData, { merge: true });

					await firestore
						.doc(`messaging/${agencyId}`)
						.set({ id: agencyId, parentId: agencyId, parentType: 'agency', isGeneral: true });
				} else if (
					role === ROLES.entreprise ||
					clientType === CLIENT_TYPE.company ||
					role === ROLES.collaborator
				) {
					agencyData = (await firestore.doc(`agencies/${agencyId}`).get()).data();
				}

				const language = navigator.language;
				const userData =
					role === ROLES.moa && clientType === CLIENT_TYPE.individual
						? {
								name: surname,
								surname: name,
								phone: phoneNumber,
								email: emailAddress,
								date: new Date().toISOString(),
								imgUrl: O_Opus,
								uid,
								language: i18n.language,
								lastConnexion: new Date().toISOString(),
								active: true,
								type: 'clients',
							}
						: {
								name: surname,
								surname: name,
								phone: phoneNumber,
								email: emailAddress,
								agency: agencyId,
								date: new Date().toISOString(),
								imgUrl: O_Opus,
								uid,
								subtype:
									role === ROLES.collaborator || !createCompany ? 'collaborator' : 'administrator',
								language: i18n.language,
								lastConnexion: new Date().toISOString(),
								active: true,
								type: 'collaborators',
								waitingStatus: role === ROLES.collaborator || !createCompany ? 'pending' : 'accepted',
							};

				await firestore
					.doc(
						role === ROLES.moa && clientType === CLIENT_TYPE.individual
							? `clients/${uid}`
							: `agencies/${agencyId}/collaborators/${uid}`
					)
					.set(userData, { merge: true });

				if (!(role === ROLES.moa && clientType === CLIENT_TYPE.individual)) {
					const call = httpsCallable(functions, 'addUserToAgency');
					call({
						userData: { ...userData, id: uid, type: 'collaborators', agency: agencyId },
						agencyId,
					}).then((r) => {});
				}

				setIsLoading3(true);

				await firestore
					.doc(
						userData.type === 'collaborators'
							? `agencies/${agencyId}/collaborators/${uid}/projects/${projectId}`
							: `clients/${uid}/projects/${projectId}`
					)
					.set({
						id: projectId,
						lastClick: null,
						active: true,
						name: projectName,
						tag: projectName,
						waitingStatus: 'pending',
						roleIds: role === ROLES.moa ? ['0'] : roleIds,
					});
				sendNotificationToCollaborators(
					createdByConstructor(uid, userData.type, agencyId),
					role === ROLES.collaborator || createCompany ? projectMandataireId : agencyId,
					projectId,
					'joinProject',
					{ ...agencyData, roleIds: role === ROLES.moa ? ['0'] : roleIds },
					[{ ...userData, status: 'pending' }],
					userData.surname + ' ' + userData.name,
					projectName,
					projectImg
				);
				setTimeout(() => {
					navigate(`/projets`, {
						state: { waitForApproval: true, projectId, projectName, projectImg, projectPercentage },
					});
				}, 2000);
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				alert(errorMessage);
			});
	};

	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		window.addEventListener('resize', Update);
		Update();
	});

	function Update() {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	}

	const mockNotification = {
		source: 'joinProject',
		senderName:
			createCompany && (role === ROLES.entreprise || clientType === CLIENT_TYPE.company)
				? companyName
				: name + ' ' + surname,
		createdAt: new Date().toISOString(),
		roleIds: role === ROLES.moa ? ['0'] : roleIds,
		isCompany: createCompany && (role === ROLES.entreprise || clientType === CLIENT_TYPE.company),
	};

	return (
		<>
			<div className={'yourScreenIsToShort'}>
				<ProjectToJoin
					projectId={projectId}
					agencyProject={agencyProject}
					projectName={projectName}
					diminutifAgencyProject={diminutifAgencyProject}
					projectImg={projectImg}
					projectPercentage={projectPercentage}
					handleNextStep={handleNextStep}
					marginTop={marginTop}
					alreadyConnected={alreadyConnected}
					isLoading={isLoading}
					isPhone={true}
				/>
			</div>
			<div className={'joinProjectPage'}>
				<div className={'containerLeftPart'} style={{ width: width < 1100 ? '100vw' : widthLeft }}>
					<HeaderJoinProject projectName={projectName} />
					{step === STEPS.projectToJoin ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<ProjectToJoin
								projectId={projectId}
								agencyProject={agencyProject}
								projectName={projectName}
								diminutifAgencyProject={diminutifAgencyProject}
								projectImg={projectImg}
								projectPercentage={projectPercentage}
								handleNextStep={handleNextStep}
								marginTop={marginTop}
								alreadyConnected={alreadyConnected}
								isLoading={isLoading}
							/>
						</div>
					) : step === STEPS.yourProfile ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<ProfileStep
								lastStep={STEPS.projectToJoin}
								setStep={setStep}
								name={name}
								setName={setName}
								surname={surname}
								setSurname={setSurname}
								phoneNumber={phoneNumber}
								setPhoneNumber={setPhoneNumber}
								emailAddress={emailAddress}
								setEmailAddress={setEmailAddress}
								handleNextStep={handleNextStep}
								marginTop={marginTop}
								projectName={projectName}
							/>
						</div>
					) : step === STEPS.securityProfile ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<SecurityProfileStep
								lastStep={STEPS.yourProfile}
								setStep={setStep}
								password={password}
								setPassword={setPassword}
								confirmPassword={confirmPassword}
								setConfirmPassword={setConfirmPassword}
								handleNextStep={handleNextStep}
								marginTop={marginTop}
								projectName={projectName}
							/>
						</div>
					) : step === STEPS.selectRole ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<SelectRoleStep
								lastStep={STEPS.securityProfile}
								agencyProject={agencyProject}
								setStep={setStep}
								setRole={setRole}
								role={role}
								handleNextStep={handleNextStepSelectRole}
								handleNextStepEmployeeStatus={handleNextStepEmployeeStatus}
								marginTop={marginTop}
								diminutifAgencyProject={diminutifAgencyProject}
								projectName={projectName}
							/>
						</div>
					) : step === STEPS.clientChoice ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							{/* For later */}
							<ClientTypeChoiceStep
								setStep={setStep}
								setRole={setClientRole}
								role={clientRole}
								handleNextStep={handleNextStep}
								marginTop={marginTop}
								projectName={projectName}
							/>
						</div>
					) : step === STEPS.clientCompanyOrIndividual ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<ClientCompanyOrIndividual
								lastStep={userType?.length > 0 ? STEPS.securityProfile : STEPS.selectRole}
								setStep={setStep}
								role={clientType}
								setRole={setClientType}
								handleNextStep={handleNextStepCompanyOrIndividual}
								marginTop={marginTop}
								projectName={projectName}
							/>
						</div>
					) : step === STEPS.infosEntrepriseClient ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<InfosEntrepriseStep
								lastStep={STEPS.clientCompanyOrIndividual}
								setStep={setStep}
								companyName={companyName}
								setCompanyName={setCompanyName}
								diminutif={diminutif}
								setDiminutif={setDiminutif}
								handleNextStep={handleNextStep}
								marginTop={marginTop}
								setRoleIds={setRoleIds}
								roleIds={roleIds}
								projectName={projectName}
							/>
						</div>
					) : step === STEPS.chooseEntreprise ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<ChooseACompanyStep
								lastStep={userType?.length > 0 ? STEPS.securityProfile : STEPS.selectRole}
								createCompany={createCompany}
								setCreateCompany={setCreateCompany}
								handleNextStep={handleNextStepChooseACompany}
								setStep={setStep}
								setSelectedCid={setSelectedCid}
								companyIds={companyIds}
								selectedCid={selectedCid}
								marginTop={marginTop}
								projectName={projectName}
								agencyProject={agencyProject}
								setCompanyName={setCompanyName}
							/>
						</div>
					) : step === STEPS.infosEntreprise ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<InfosEntrepriseStep
								lastStep={STEPS.chooseEntreprise}
								setStep={setStep}
								companyName={companyName}
								setCompanyName={setCompanyName}
								diminutif={diminutif}
								setDiminutif={setDiminutif}
								handleNextStep={handleNextStep}
								marginTop={marginTop}
								setRoleIds={setRoleIds}
								roleIds={roleIds}
								projectName={projectName}
							/>
						</div>
					) : step === STEPS.accessProjectLastStep ? (
						<div style={{ opacity: opacity }} className={'animationJoinProject'}>
							<AccessProjectFinalStep
								agencyToNotifyName={createCompany || role === ROLES.moa ? agencyProject : companyName}
								projectName={projectName}
								marginTop={marginTop}
								handleFinish={handleFinish}
								isLoading={isLoading}
								isLoading1={isLoading1}
								isLoading2={isLoading2}
								isLoading3={isLoading3}
								mockNotification={mockNotification}
							/>
						</div>
					) : null}
					<FooterJoinProject />
				</div>

				<div className={'containerRightPart'} style={{ width: widthSecondPart, marginRight: marginLeftForm }}>
					<div className={'calqueInfosProjet'}>
						<div className={'firstSlide'}>
							<img src={projectImg} alt="" className={'projectImg'} />
							<p className={'projectName'}>{projectName}</p>
						</div>

						<div className={'secondSlide'}>
							<p className={'agencyProject'}>{agencyProject}</p>
						</div>

						<div className={'thirdSlide'}>
							<p className={'projectName'}>{projectName}</p>
						</div>

						<div className={'fourthSlide'}>
							<img src={projectImg} alt="" className={'projectImg'} />
						</div>

						<div className={'containerQrCode'}>
							<div className={'backGroundQrCode'}>
								<QRCode
									size={256}
									style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
									value={urlComplete}
									viewBox={`0 0 256 256`}
								/>
							</div>
							<img src={leftIcon} alt="" className={'leftIcon'} />
							<p className={'scanThisQrCode'}>{t('translation.scanThisQrCode')}</p>
						</div>
					</div>
					<img src={ImageJoinProject} alt="" className={'rightPartJoinProject'} />
				</div>
			</div>
		</>
	);
}

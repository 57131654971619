import { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../../firebase/config';
import { removeDuplicates } from '../../useful/UsefulFunctions';
import { GROUP_COLLECTION, PROJECT_COLLECTION } from '../../firebase/paths';
import AgencyContext from '../../contexts/agencyContext';
import { useProjectContext } from '../../contexts/projectContext';
import TypeContext from '../../contexts/typeContext';
import PermissionGroupsContext from '../../contexts/permissionGroupsContext';
import PartnersContext from '../../contexts/partnersContext';
import ClientsContext from '../../contexts/clientsContext';
import { rolesWithMOA } from './utils';
import { useTranslation } from 'react-i18next';
import PagePermissionsContext from '../../contexts/pagePermissions';
import { joinPaths } from '../../firebase/utils';
import { Paths } from '../../firebase/paths';

export default function WrapperContext({ children, pagePermissions, setPagePermissions }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();

	const [partners, setPartners] = useState([]);
	const [clients, setClients] = useState(null);
	const [permissionsGroups, setPermissionsGroups] = useState([]);

	useEffect(() => {
		if (project?.id && uid && (type === 'clients' || agencyId)) {
			const subscriber = firestore.collection(`projects/${project.id}/accounts`).onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					const partners = [];
					const clients = [];
					const promisesPartners = [];
					const promisesClients = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists) {
							if (doc.data().type === 'agencies') {
								const promise = firestore
									.doc(`agencies/${doc.id}`)
									.get()
									.then((doc1) => {
										if (doc1 && doc1.exists) {
											if (
												doc.data().roleIds &&
												doc.data().roleIds.includes(rolesWithMOA(t)[0].id)
											) {
												clients.push({ ...doc.data(), ...doc1.data(), id: doc1.id });
											} else {
												if (doc.id !== agencyId) {
													partners.push({ ...doc.data(), ...doc1.data(), id: doc1.id });
												}
											}
										}
									});
								if (doc.data().roleIds && doc.data().roleIds.includes(rolesWithMOA(t)[0].id)) {
									promisesClients.push(promise);
								} else {
									promisesPartners.push(promise);
								}
							} else {
								if (doc.id !== uid) {
									const promise = firestore
										.doc(`clients/${doc.id}`)
										.get()
										.then((doc1) => {
											if (doc1 && doc1.exists) {
												clients.push({ ...doc.data(), ...doc1.data(), id: doc1.id });
											}
										});
									promisesClients.push(promise);
								}
							}
						}
					});
					Promise.all(promisesPartners).then(() => {
						setPartners(partners);
					});
					Promise.all(promisesClients).then(() => {
						setClients((prevState) => removeDuplicates([...(prevState ?? []), ...clients], 'id'));
					});
				}
			});
			return () => subscriber();
		}
	}, [project?.id, agencyId, type, uid]);

	useEffect(() => {
		if (project?.id && type && type !== 'clients') {
			const subscriber1 = firestore
				.collection(`projects/${project.id}/accounts`)
				.where('type', '==', 'clients')
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const clients = [];
						const promises = [];
						querySnapshot.forEach((doc) => {
							if (doc && doc.exists) {
								const promise = firestore
									.doc(`clients/${doc.id}`)
									.get()
									.then((doc) => {
										if (doc && doc.exists) {
											clients.push({ ...doc.data(), id: doc.id });
										}
									});
								promises.push(promise);
							}
						});
						Promise.all(promises).then(() => {
							setClients((prevState) => removeDuplicates([...(prevState ?? []), ...clients], 'id'));
						});
					}
				});
			const subscriber2 = firestore
				.collection(`${PROJECT_COLLECTION}/${project.id}/${GROUP_COLLECTION}`)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot) {
						const groups = [];
						querySnapshot.forEach((doc) => {
							if (doc && doc.exists) {
								groups.push({ ...doc.data(), id: doc.id });
							}
						});
						setPermissionsGroups(groups);
					}
				});
			return () => {
				subscriber1();
				subscriber2();
			};
		} else if (project?.id && type) {
			setClients([]);
		}
	}, [project?.id, type]);

	useEffect(() => {
		if (project?.id && agencyId) {
			const subscriber = firestore.doc(joinPaths(Paths.PROJECTS, project.id, Paths.ACCOUNTS, agencyId)).onSnapshot((doc) => {
				if (doc && doc.exists) {
					setPagePermissions(doc.data().permissions ?? { progression: 'all', finances: 'all' });
				}
			});
			return () => subscriber();
		}
	}, [project?.id, agencyId]);

	return (
		<PartnersContext.Provider value={[partners, setPartners]}>
			<ClientsContext.Provider value={[clients, setClients]}>
				<PermissionGroupsContext.Provider value={[permissionsGroups, setPermissionsGroups]}>
					<PagePermissionsContext.Provider value={[pagePermissions, setPagePermissions]}>
						{children}
					</PagePermissionsContext.Provider>
				</PermissionGroupsContext.Provider>
			</ClientsContext.Provider>
		</PartnersContext.Provider>
	);
}

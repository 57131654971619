import Modal from 'react-modal';
import './styles.css';
import { useState, useContext, useEffect } from 'react';
import { firestore } from '../../firebase/config';
import { joinPaths } from '../../firebase/utils';
import { Paths } from '../../firebase/paths';
import { useProjectContext } from '../../contexts/projectContext';
import crossIcon from '../../assets/crossIcon.png';
import bottomArrow from '../../assets/bottomArrow.svg';
import ProgressionAppIcon from '../../assets/ProgressionAppIcon.png';
import FinanceAppIcon from '../../assets/FinanceAppIcon.png';
import { useTranslation } from 'react-i18next';

export default function ModalPartnerPermissions({
	isOpen,
	onClose,
	partnerId,
	partnerName,
	withOverlay,
	withSaveButton,
	handleSave,
}) {
	const { t } = useTranslation();

	const [currentProject] = useProjectContext();
	const [progression, setProgression] = useState('readonly');
	const [finances, setFinances] = useState('readonly');
	const [progressionSaved, setProgressionSaved] = useState(false);
	const [financesSaved, setFinancesSaved] = useState(false);

	useEffect(() => {
		if (currentProject && partnerId) {
			firestore
				.doc(joinPaths(Paths.PROJECTS, currentProject.id, Paths.ACCOUNTS, partnerId))
				.get()
				.then((doc) => {
					if (doc.exists) {
						if (doc.data().permissions) {
							setProgression(doc.data().permissions.progression);
							setFinances(doc.data().permissions.finances);
						} else {
							setProgression('all');
							setFinances('all');
						}
					}
				});
		}
	}, [currentProject, partnerId]);

	const progressionOptions = [
		{
			value: 'none',
			label: t('permissions.none'),
			info: t('permissions.none_info_progression'),
		},
		{
			value: 'readonly',
			label: t('permissions.readonly'),
			info: t('permissions.readonly_info_progression'),
		},
		{
			value: 'all',
			label: t('permissions.all'),
			info: t('permissions.all_info_progression'),
		},
	];

	const financesOptions = [
		{
			value: 'none',
			label: t('permissions.none'),
			info: t('permissions.none_info_finances'),
		},
		{
			value: 'readonly',
			label: t('permissions.readonly'),
			info: t('permissions.readonly_info_finances'),
		},
		{
			value: 'all',
			label: t('permissions.all'),
			info: t('permissions.all_info_finances'),
		},
	];

	const handleProgression = (value) => {
		setProgression(value);
		if (!withSaveButton && currentProject && partnerId) {
			firestore
				.doc(joinPaths(Paths.PROJECTS, currentProject.id, Paths.ACCOUNTS, partnerId))
				.update({
					'permissions.progression': value,
				})
				.then(() => {
					setProgressionSaved(true);

					setTimeout(() => {
						setProgressionSaved(false);
					}, 4000);
				});
		}
	};

	const handleFinances = (value) => {
		setFinances(value);

		if (!withSaveButton && currentProject && partnerId) {
			firestore
				.doc(joinPaths(Paths.PROJECTS, currentProject.id, Paths.ACCOUNTS, partnerId))
				.update({
					'permissions.finances': value,
				})
				.then(() => {
					setFinancesSaved(true);

					setTimeout(() => {
						setFinancesSaved(false);
					}, 4000);
				});
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			className="modalPartnerPermissions"
			overlayClassName={`overlayModalPartnerPermissions ${withOverlay ? 'withOverlay' : ''}`}>
			<img src={crossIcon} alt="" className={'modalPartnerPermissions__crossIcon'} onClick={onClose} />

			<div className="modalPartnerPermissions__container">
				<h1>{t('permissions.management_permissions')}</h1>
				<p className="modalPartnerPermissions__description">
					{t('permissions.management_permissions_info', { partnerName: partnerName })}
				</p>
				<div className="modalPartnerPermissions__line" />
				<div className="modalPartnerPermissions__grid">
					<div className="modalPartnerPermissions__item">
						<div className="modalPartnerPermissions__item__header">
							<img src={ProgressionAppIcon} alt="progression" style={{ borderRadius: '10px' }} />
							<h2>{t('common.progression')}</h2>
						</div>
						{progressionSaved && (
							<p className="modalPartnerPermissions__saved">{t('common.saved_modification')}</p>
						)}
						<CustomSelect value={progression} onChange={handleProgression} options={progressionOptions} />
					</div>

					<div className="modalPartnerPermissions__item">
						<div className="modalPartnerPermissions__item__header">
							<img src={FinanceAppIcon} alt="finances" style={{ borderRadius: '10px' }} />
							<h2>{t('common.finances')}</h2>
						</div>
						{financesSaved && (
							<p className="modalPartnerPermissions__saved">{t('common.saved_modification')}</p>
						)}
						<CustomSelect value={finances} onChange={handleFinances} options={financesOptions} />
					</div>
				</div>
			</div>

			{withSaveButton && (
				<button
					className="modalPartnerPermissions__button"
					onClick={() =>
						handleSave({
							progression: progression,
							finances: finances,
						})
					}>
					<p>{t('permissions.add_partner_to_project', { partnerName: partnerName })}</p>
				</button>
			)}
		</Modal>
	);
}

const CustomSelect = ({ value, onChange, options }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [hoveredOption, setHoveredOption] = useState(null);

	const handleClickOutside = (event) => {
		if (!event.target.closest('.modalPartnerPermissions__customSelect')) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen]);

	return (
		<div className="modalPartnerPermissions__customSelect">
			<div
				className={`modalPartnerPermissions__selectedOption ${isOpen ? 'opened' : ''}`}
				onClick={() => setIsOpen((p) => !p)}>
				<p>{options.find((opt) => opt.value === value)?.label}</p>
				<img src={bottomArrow} alt="arrow down" />
			</div>

			{isOpen && (
				<ul className="modalPartnerPermissions__options">
					{options.map((option) => (
						<li
							key={option.value}
							className="modalPartnerPermissions__option"
							onClick={() => {
								onChange(option.value);
								setIsOpen(false);
							}}
							onMouseEnter={() => setHoveredOption(option)}
							onMouseLeave={() => setHoveredOption(null)}>
							<p>{option.label}</p>
							{hoveredOption === option && (
								<p className="modalPartnerPermissions__optionInfo">{option.info}</p>
							)}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider, CustomProvider } from 'firebase/app-check';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase app
export const firebaseApp = initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

// Compat versions for Firestore and Storage
export const firestore = firebase.firestore();
export const storage = firebase.storage();

// New SDK versions for Auth and Functions
export const auth = getAuth(firebaseApp);
export const functions = getFunctions(firebaseApp, 'europe-west1');

// App Check initialization
let appCheck;
if (process.env.NODE_ENV === 'development') {
	const customProvider = {
		getToken: () =>
			Promise.resolve({
				token: 'development-token',
				expireTimeMillis: Date.now() + 3600000, // 1 hour from now
			}),
	};
	appCheck = initializeAppCheck(firebaseApp, {
		provider: new CustomProvider(customProvider),
		isTokenAutoRefreshEnabled: true,
	});
} else {
	appCheck = initializeAppCheck(firebaseApp, {
		provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
		isTokenAutoRefreshEnabled: true,
	});
}
export { appCheck };

/*if (process.env.NODE_ENV === 'development') {
	// Compat emulators
	firestore.useEmulator('localhost', 8080);
	storage.useEmulator('localhost', 9199);

	// New SDK emulators
	connectAuthEmulator(auth, 'http://localhost:9099');
	connectFunctionsEmulator(functions, 'localhost', 5001);

	// Disable App Check for local development
	self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}*/

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ItemAccessibility({ permission, name, diminutif, onClick, disabled }) {
	const { t } = useTranslation();

	return (
		<div style={{ opacity: disabled ? 0.5 : 1 }}>
			<div
				className={
					permission !== 'hidden'
						? `itemAccessibilityActivated ${permission} transition`
						: `itemAccessibility transition`
				}>
				<div style={{ display: 'flex' }}>
					<p className={'titleAccessibility'} style={{ color: getPrimaryColor(permission) }}>
						{name}
					</p>

					<p className={'littleTitleAccessibility'} style={{ color: getPrimaryColor(permission) }}>
						{diminutif}
					</p>
					<div
						className={'roundedButton'}
						style={{
							borderColor: getSecondaryColor(permission),
						}}>
						<div
							className={'littleCircle'}
							style={{
								backgroundColor: getSecondaryColor(permission),
							}}
						/>
					</div>
					<div className={'containerChoiceRounded'}>
						<div
							style={{ display: 'flex', alignItems: 'center' }}
							className={'hover'}
							onClick={() => onClick('hidden')}>
							<div className={'roundedButtonChoice'} style={{ borderColor: getSecondaryColor('hidden') }}>
								<div
									className={'littleCircle'}
									style={{ backgroundColor: getSecondaryColor('hidden') }}
								/>
							</div>
							<p className={'designantChoice'} style={{ color: getPrimaryColor('hidden') }}>
								{t('permissions.hidden')}
							</p>
						</div>

						<div
							style={{ display: 'flex', alignItems: 'center' }}
							className={'hover'}
							onClick={() => onClick('readonly')}>
							<div className={'roundedButtonChoice'} style={{ borderColor: getPrimaryColor('readonly') }}>
								<div
									className={'littleCircle'}
									style={{ backgroundColor: getPrimaryColor('readonly') }}
								/>
							</div>
							<p className={'designantChoice'} style={{ color: getPrimaryColor('readonly') }}>
								{t('permissions.read')}
							</p>
						</div>

						<div
							style={{ display: 'flex', alignItems: 'center' }}
							className={'hover'}
							onClick={() => onClick('all')}>
							<div className={'roundedButtonChoice'} style={{ borderColor: getPrimaryColor('all') }}>
								<div className={'littleCircle'} style={{ backgroundColor: getPrimaryColor('all') }} />
							</div>
							<p className={'designantChoice'} style={{ color: getPrimaryColor('all') }}>
								{t('permissions.read_and_add')}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function getPrimaryColor(status) {
	if (status === 'all') return '#2D56D5';
	if (status === 'readonly') return '#ee8233';
	return '#4d4d4d';
}

function getSecondaryColor(status) {
	if (status === 'all') return '#2D56D5';
	if (status === 'readonly') return '#ee8233';
	return '#A2A2A2';
}

function getBackgroundColor(status) {
	if (status === 'all') return '#EAEEFB';
	if (status === 'readonly') return '#FEE7D1';
	return 'transparent';
}

import React, { useContext, useState, useEffect } from 'react';
import './_documentInfos.scss';
import ItemAccessibility from './components/ItemAccessibility';
import ButtonsDocumentInfos from './components/ButtonsDocumentInfos';
import { chooseIconFile, formatBytes, modifyPermissionsFile, trashId } from '../../utils';
import AgencyContext from '../../../../../contexts/agencyContext';
import TypeContext from '../../../../../contexts/typeContext';
import PdfViewer from '../../../../../components/PdfViewer';
import SeenByComponent from './components/SeenByComponent';
import { auth, firestore } from '../../../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../firebase/paths';
import multipleSelection from '../../../../../assets/multipleSelection.svg';
import { useTranslation } from 'react-i18next';
import { createdByConstructor } from '../../../utils';
import { usePartnersContext } from '../../../../../contexts/partnersContext';
import {
	NotificationSource,
	sendNotificationToClients,
	sendNotificationToCollaborators,
} from '../../../Notifications/utils';
import AuthDataContext from '../../../../../contexts/authDataContext';
import { useProjectContext } from '../../../../../contexts/projectContext';
import { useClientsContext } from '../../../../../contexts/clientsContext';

export default function DocumentInfos({
	projectId,
	selectedFileIds,
	setSelectedFileIds,
	files,
	setFiles,
	path,
	setPath,
	setModalMoveItem,
	filterPartnerId,
	parentFolder,
}) {
	const { t, i18n } = useTranslation();

	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);
	const userType = useContext(TypeContext);
	const [partners] = usePartnersContext();
	const authData = useContext(AuthDataContext);
	const [project] = useProjectContext();
	const [clients] = useClientsContext();

	const isSelecting = selectedFileIds.length > 1;
	const file = files.find((file) => file.id === selectedFileIds[0]);
	const selectedFiles = files.filter((file) => selectedFileIds.includes(file.id));
	const canModify = selectedFiles.every(
		(it) => it.createdBy.agencyId === agencyId || it.createdBy.userId === uid || agencyId === project?.creator
	);
	const isInTrash = file?.path?.includes(trashId);
	const [extendConsult, setExtendConsult] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const restoreFile = async () => {
		setSelectedFileIds([]);
		setIsLoading(true);
		const batch = firestore.batch();
		for (const it of selectedFiles) {
			const docRef = firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${it.id}`);

			if (it.isFolder) {
				const subFiles = (
					await firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('path', '>=', `${it.path}${it.id}/`)
						.where('path', '<', `${it.path}${it.id}/\uf8ff`)
						.get()
				).docs.map((it) => ({ ...it.data(), id: it.id }));
				for (const subFile of subFiles) {
					batch.update(firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${subFile.id}`), {
						path: subFile.previousPath,
						previousPath: null,
						deletedAt: null,
						modifiedAt: new Date().toISOString(),
					});
				}
			}

			batch.update(docRef, {
				path: it.previousPath,
				previousPath: null,
				deletedAt: null,
				modifiedAt: new Date().toISOString(),
			});
		}
		await batch.commit();
		setIsLoading(false);
	};

	const handlePermission = async (partnerId, previousPermission, permission) => {
		if (partnerId === 'client') {
			for (let i = 0; i < selectedFiles.length; i++) {
				await modifyPermissionsFile(
					uid,
					userType,
					agencyId,
					projectId,
					setFiles,
					selectedFiles[i],
					null,
					true,
					null,
					permission === 'readonly',
					selectedFiles[i].isFolder,
					permission === 'hidden'
				);
			}
			if (permission !== 'hidden' && previousPermission === 'hidden') {
				await sendNotificationToClients(
					createdByConstructor(uid, userType, agencyId),
					projectId,
					NotificationSource.FILES,
					parentFolder,
					selectedFiles,
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
				for (const client of clients) {
					if (client.type === 'agencies') {
						sendNotificationToCollaborators(
							createdByConstructor(uid, userType, agencyId),
							client.id,
							projectId,
							NotificationSource.FILES,
							parentFolder,
							selectedFiles,
							authData?.surname + ' ' + authData?.name,
							project?.name,
							project?.imgUrl
						);
					}
				}
			}
		} else {
			for (let i = 0; i < selectedFiles.length; i++) {
				await modifyPermissionsFile(
					uid,
					userType,
					agencyId,
					projectId,
					setFiles,
					selectedFiles[i],
					[partnerId],
					false,
					permission === 'readonly' ? [partnerId] : null,
					false,
					selectedFiles[i].isFolder,
					permission === 'hidden'
				);
			}
			if (permission !== 'hidden' && previousPermission === 'hidden') {
				sendNotificationToCollaborators(
					createdByConstructor(uid, userType, agencyId),
					partnerId,
					projectId,
					NotificationSource.FILES,
					parentFolder,
					selectedFiles,
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
			}
		}
	};

	return (
		<div className={'documentInfos'}>
			<div className={'itemInfos'}>
				{isSelecting ? (
					<img src={multipleSelection} alt="" className={'multipleSelection'} />
				) : (
					<>
						{file?.type === 'application/pdf' ? (
							<div className={'folderIcon'}>
								<PdfViewer file={file?.url} isPreview={true} width={100} />
							</div>
						) : (
							<img
								src={
									isSelecting
										? chooseIconFile(null)
										: file?.isFolder
											? chooseIconFile('folder')
											: file?.type?.includes('image') ||
												  file?.type?.includes('heic') ||
												  file?.type?.includes('heif')
												? file?.url
												: chooseIconFile(file?.type)
								}
								alt=""
								className={'folderIcon'}
							/>
						)}
					</>
				)}

				<p className={'itemName'}>{isSelecting ? t('translation.selection') : file?.name}</p>
				<p className={'complementaryInfo'}>
					{isSelecting
						? `${selectedFileIds.length} élément${selectedFileIds.length > 1 ? 's' : ''} sélectionné${
								selectedFileIds.length > 1 ? 's' : ''
							}`
						: (file?.isFolder ? '' : formatBytes(file?.size) + ' • ') +
							new Intl.DateTimeFormat(i18n.language, {
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							}).format(new Date(file?.createdAt))}
				</p>
			</div>

			{!file?.isOptionFolder && (
				<ButtonsDocumentInfos
					projectId={projectId}
					files={files}
					setFiles={setFiles}
					path={path}
					setPath={setPath}
					selectedFileIds={selectedFileIds}
					setSelectedFileIds={setSelectedFileIds}
					canModify={canModify}
					isInTrash={isInTrash}
					selectedFiles={selectedFiles}
					setModalMoveItem={setModalMoveItem}
					setIsLoading={setIsLoading}
					isLoading={isLoading}
				/>
			)}

			{!file?.isOptionFolder && canModify && !isInTrash && (
				<div className={'containerAccess'}>
					<p className={'accessibilityTitle'}>{t('translation.accessibility')}</p>

					{/*<PermissionGroupPart />*/}

					{userType !== 'clients' &&
						(filterPartnerId === agencyId || filterPartnerId === 'clients') &&
						selectedFiles.every(
							(it) =>
								it.createdBy.agencyId === agencyId ||
								it.createdBy.userId === uid ||
								(it.createdBy.userType !== 'client' &&
									!clients?.map((item) => item.id).includes(it.createdBy.agencyId) &&
									agencyId === project?.creator)
						) && (
							<ItemAccessibility
								name={t('common.mo')}
								permission={
									selectedFiles.every((it) => it.clientPermissionReadOnly)
										? 'readonly'
										: selectedFiles.every((it) => it.clientPermission)
											? 'all'
											: 'hidden'
								}
								onClick={async (newValue) => {
									const previousValue = selectedFiles.every((it) => it.clientPermissionReadOnly)
										? 'readonly'
										: selectedFiles.every((it) => it.clientPermission)
											? 'all'
											: 'hidden';
									await handlePermission('client', previousValue, newValue);
								}}
								diminutif={t('translation.terms.0.acronym')}
							/>
						)}

					{clients.length === 0 && partners.length === 0 && (
						<p className={'noPartners'}>{t('translation.addMoAndPartners')}</p>
					)}

					{userType !== 'clients' && clients.length > 0 && partners.length === 0 && (
						<p className={'noPartners'}>{t('translation.addPartners')}</p>
					)}

					{filterPartnerId === agencyId
						? partners
								.filter((it) => !selectedFiles.some((file) => file.createdBy.agencyId === it.id))
								.map((it) => (
									<ItemAccessibility
										key={it.id}
										name={it.name}
										permission={
											selectedFiles.every((file) => file.permissionsReadOnly?.includes(it.id))
												? 'readonly'
												: selectedFiles.every((file) => file.permissions?.includes(it.id))
													? 'all'
													: 'hidden'
										}
										onClick={async (newValue) => {
											const previousValue = selectedFiles.every((file) =>
												file.permissionsReadOnly?.includes(it.id)
											)
												? 'readonly'
												: selectedFiles.every((file) => file.permissions?.includes(it.id))
													? 'all'
													: 'hidden';
											await handlePermission(it.id, previousValue, newValue);
										}}
										diminutif={it.diminutifAgency ?? getFirstLetters(it.name)}
									/>
								))
						: filterPartnerId !== 'clients' && (
								<ItemAccessibility
									name={partners.find((it) => it.id === filterPartnerId)?.name}
									permission={
										selectedFiles.every((file) =>
											file.permissionsReadOnly?.includes(filterPartnerId)
										)
											? 'readonly'
											: selectedFiles.every((file) => file.permissions?.includes(filterPartnerId))
												? 'all'
												: 'hidden'
									}
									onClick={async (newValue) => {
										const previousValue = selectedFiles.every((file) =>
											file.permissionsReadOnly?.includes(filterPartnerId)
										)
											? 'readonly'
											: selectedFiles.every((file) => file.permissions?.includes(filterPartnerId))
												? 'all'
												: 'hidden';
										await handlePermission(filterPartnerId, previousValue, newValue);
									}}
									diminutif={
										partners.find((it) => it.id === filterPartnerId)?.diminutifAgency ??
										getFirstLetters(partners.find((it) => it.id === filterPartnerId)?.name)
									}
								/>
							)}
				</div>
			)}

			{isInTrash && (
				<button onClick={restoreFile} className={'restoreButton'}>
					{isLoading ? t('translation.restoreInProgress') : t('translation.restoreElementToOriginalPlace')}
				</button>
			)}

			{!file?.isOptionFolder && canModify && (
				<div className={'containerConfirmLecture'}>
					<div className={'bar'} />
					<div className={'header'}>
						<p className={'title'}>
							{t('translation.consultConfirm')}{' '}
							<span className={'seenLength'}>{file?.seenBy.length}</span>
						</p>
						<p
							className={'buttonPlusMoins'}
							onClick={() => {
								if (extendConsult) {
									setExtendConsult(false);
								} else {
									setExtendConsult(true);
								}
							}}>
							{extendConsult ? t('translation.reduce') : t('common.see_more')}
						</p>
					</div>

					<div className={'listConsult'} style={{ height: extendConsult ? null : 0 }}>
						{file?.seenBy.map((it, index) => (
							<SeenByComponent key={index} userId={it.userId} seenAt={it.seenAt} />
						))}
					</div>

					<div className={'bar'} style={{ marginTop: 3 }} />
				</div>
			)}

			<div style={{ height: 80, width: 30 }} />
		</div>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../../assets/crossIcon.png';
import checkBleu from '../../../../assets/checkBleu.png';
import deleteIcon from '../../../../assets/deleteIcon.png';
import { auth, firestore, storage } from '../../../../firebase/config';
import { useNavigate } from 'react-router-dom';
import AgencyContext from '../../../../contexts/agencyContext';
import Resizer from 'react-image-file-resizer';
import LockIcon from '../../../../assets/LockIcon.png';
import checkBlanc from '../../../../assets/checkBlanc.png';
import { deleteStorageFromUrl, generateUniqueFirestoreId } from '../../../../firebase/utils';
import { useTranslation } from 'react-i18next';
import './_informations.scss';
import { FILE_COLLECTION } from '../../../../firebase/paths';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';

export default function Informations({ uid, projectId, projectData, setProjectData, isMandataire, partnerAgencies }) {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const agency = useContext(AgencyContext);

	const [widthEnregistre, setWidthEnregistre] = useState(0);
	const [heightEnregistre, setHeightEnregistre] = useState(0);
	const [opacityEnregistre, setOpacityEnregistre] = useState(0);

	const [newProjectData, setNewProjectData] = useState(projectData);
	const [imgFile, setImgFile] = useState();
	const [tag, setTag] = useState();
	const [newTag, setNewTag] = useState();

	const [financeActive, setFinanceActive] = useState(false);
	const [matterportActive, setMatterportActive] = useState(false);

	// Modaux changement de statut projet

	const [modalProjetAcheve, setModalProjetAcheve] = useState(false);
	const [modalProjetEnCours, setModalProjetEnCours] = useState(false);

	// Suppression projet

	const [canDeleteProject, setCanDeleteProject] = useState(false);

	const [modalConfirmationSuppressionProjet, setModalConfirmationSuppressionProjet] = useState(false);
	const [authPassword, setAuthPassword] = useState();
	const [motDePasseSuppression, setMotDePasseSuppression] = useState(false);
	const [error, setError] = useState();

	const [suppresionLoading, setSuppressionLoading] = useState(false);
	const [suppressionAlbum, setSuppresionAlbum] = useState(false);
	const [suppressionMessaging, setSuppresionMessaging] = useState(false);
	const [suppressionCalendar, setSuppresionCalendar] = useState(false);
	const [suppressionTasks, setSuppresionTasks] = useState(false);
	const [suppressionFiles, setSuppresionFiles] = useState(false);
	const [suppressionProgression, setSuppresionProgression] = useState(false);
	const [suppressionFinance, setSuppresionFinance] = useState(false);

	useEffect(() => {
		const subscriber2 = firestore.doc(`projects/${projectId}`).onSnapshot((documentSnapshot) => {
			if (documentSnapshot && documentSnapshot.exists) {
				setFinanceActive(!documentSnapshot.data().withoutFinance);
				setMatterportActive(documentSnapshot.data().withMatterport ?? false);
			}
		});
		firestore
			.doc(`agencies/${agency}/collaborators/${uid}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					if (documentSnapshot.data().subtype === 'administrator') {
						setCanDeleteProject(true);
					} else if (documentSnapshot.data().subtype === 'collaborator') {
						setCanDeleteProject(false);
					} else {
						setCanDeleteProject(documentSnapshot.data().canDeleteProject);
					}
				}
			});
		firestore
			.doc(`agencies/${agency}/collaborators/${uid}/projects/${projectId}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setTag(documentSnapshot.data().tag);
					setNewTag(documentSnapshot.data().tag);
				}
			});
		return () => {
			subscriber2();
		};
	}, [projectId, agency, uid]);

	function handleChange(e) {
		setNewProjectData({ ...newProjectData, [e.target.id]: e.target.value });
	}

	const imageHandler = async (e) => {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setNewProjectData({ ...newProjectData, imgUrl: reader.result });
			}
		};
		reader.readAsDataURL(e.target.files[0]);
		setImgFile(e.target.files[0]);
	};

	async function deleteCollection(
		collectionWithCondition,
		collectionWithoutCondition,
		deleteStorage1 = false,
		attributUrl1,
		deleteStorage2 = false,
		attributUrl2
	) {
		collectionWithCondition.get().then((querySnapshot) => {
			if (querySnapshot) {
				querySnapshot.forEach(async (documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						if (deleteStorage1) {
							if (documentSnapshot.data()[attributUrl1]) {
								deleteStorageFromUrl(documentSnapshot.data()[attributUrl1]);
							}
						}
						if (deleteStorage2) {
							if (documentSnapshot.data()[attributUrl2]) {
								deleteStorageFromUrl(documentSnapshot.data()[attributUrl2]);
							}
						}
						await collectionWithoutCondition.doc(documentSnapshot.id).delete();
					}
				});
			}
		});
	}

	async function deleteMessaging(path) {
		path.get().then((querySnapshot) => {
			if (querySnapshot) {
				querySnapshot.forEach(async (documentSnapshot) => {
					await deleteCollection(
						path.doc(documentSnapshot.id).collection('pictures'),
						path.doc(documentSnapshot.id).collection('pictures'),
						true,
						'contents'
					);
					await deleteCollection(
						path.doc(documentSnapshot.id).collection('files'),
						path.doc(documentSnapshot.id).collection('files'),
						true,
						'contents'
					);
					await deleteCollection(
						path.doc(documentSnapshot.id).collection('messages'),
						path.doc(documentSnapshot.id).collection('messages')
					);
					await deleteCollection(path, path);
				});
			}
		});
	}

	async function deleteProgression(pathProject, type) {
		pathProject
			.collection(type)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach(async (documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							if (type === 'finance') {
								await deleteCollection(
									pathProject.collection(`${type}/${documentSnapshot.id}/bars`),
									pathProject.collection(`${type}/${documentSnapshot.id}/bars`),
									true,
									'fileUrl'
								);
							} else {
								await deleteCollection(
									pathProject.collection(`${type}/${documentSnapshot.id}/bars`),
									pathProject.collection(`${type}/${documentSnapshot.id}/bars`)
								);
							}
							await pathProject.collection(type).doc(documentSnapshot.id).delete();
						}
					});
				}
			});
	}

	async function deleteProject() {
		const user = auth.currentUser;
		const cred = EmailAuthProvider.credential(user.email, authPassword);

		reauthenticateWithCredential(user, cred)
			.then(async () => {
				setSuppressionLoading(true);
				const pathProject = firestore.doc(`projects/${projectId}`);

				setSuppresionMessaging(true);
				pathProject
					.collection('accounts')
					.get()
					.then((querySnapshot) => {
						if (querySnapshot) {
							querySnapshot.forEach(async (documentSnapshot) => {
								if (documentSnapshot && documentSnapshot.exists) {
									const uid = documentSnapshot.data().uid;
									const account = await firestore.doc(`accounts/${uid}`).get();
									const type = account.data().type;
									const pathAccount =
										type === 'collaborators'
											? firestore.doc(`agencies/${account.data().agency}/collaborators/${uid}`)
											: firestore.doc(`${type}/${uid}`);
									await deleteMessaging(pathAccount.collection(`projects/${projectId}/messaging`));
									await pathAccount.collection('projects').doc(projectId).delete();
									await pathProject.collection('accounts').doc(uid).delete();
								}
							});
						}
					});
				await deleteMessaging(pathProject.collection('messaging'));
				setSuppresionMessaging(false);

				pathProject
					.collection('albums')
					.get()
					.then((querySnapshot) => {
						if (querySnapshot) {
							setSuppresionAlbum(true);
							querySnapshot.forEach(async (documentSnapshot) => {
								if (documentSnapshot && documentSnapshot.exists) {
									if (documentSnapshot.data().editable) {
										await deleteCollection(
											pathProject
												.collection('albums')
												.doc(documentSnapshot.id)
												.collection('pictures'),
											pathProject
												.collection('albums')
												.doc(documentSnapshot.id)
												.collection('pictures'),
											true,
											'imgUrl'
										);
										deleteStorageFromUrl(documentSnapshot.data().imgUrl);
									} else {
										await deleteCollection(
											pathProject
												.collection('albums')
												.doc(documentSnapshot.id)
												.collection('pictures'),
											pathProject
												.collection('albums')
												.doc(documentSnapshot.id)
												.collection('pictures')
										);
									}
									await pathProject.collection('albums').doc(documentSnapshot.id).delete();
								}
							});
							setSuppresionAlbum(false);
						}
					});

				setSuppresionProgression(true);
				await deleteProgression(pathProject, 'progression');
				setSuppresionProgression(false);

				setSuppresionFinance(true);
				await deleteProgression(pathProject, 'finance');
				setSuppresionFinance(false);

				pathProject
					.collection(FILE_COLLECTION)
					.get()
					.then((querySnapshot) => {
						if (querySnapshot) {
							setSuppresionFiles(true);
							querySnapshot.forEach(async (documentSnapshot) => {
								if (documentSnapshot && documentSnapshot.exists) {
									const data = { ...documentSnapshot.data(), id: documentSnapshot.id };
									if (data.url) {
										deleteStorageFromUrl(data.url);
									}
									await pathProject.collection(FILE_COLLECTION).doc(documentSnapshot.id).delete();
								}
							});
							setSuppresionFiles(false);
						}
					});

				pathProject.get().then(async (documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						deleteStorageFromUrl(documentSnapshot.data().imgUrl);
						deleteStorageFromUrl(documentSnapshot.data().imgUrl1);
						deleteStorageFromUrl(documentSnapshot.data().imgUrl2);
						deleteStorageFromUrl(documentSnapshot.data().imgUrl3);
					}
				});

				await deleteCollection(pathProject.collection(`integration`), pathProject.collection(`integration`));

				await pathProject.delete();

				setSuppressionLoading(false);
				setModalConfirmationSuppressionProjet(false);
				setAuthPassword('');
				setMotDePasseSuppression(false);
				navigate('/projets');
			})
			.catch((error) => setError(error));
	}

	const handleUpdateProject = async () => {
		if (newProjectData || newTag !== tag) {
			if (imgFile) {
				Resizer.imageFileResizer(
					imgFile,
					2000,
					2000,
					'jpeg',
					50,
					0,
					async (file) => {
						const storageRef = storage.ref(`projects/${projectId}/pictures`);
						const fileRef = storageRef.child(generateUniqueFirestoreId());
						await fileRef.put(file);
						const imgUrl = await fileRef.getDownloadURL();

						await firestore.doc(`projects/${projectId}`).update({
							...newProjectData,
							imgUrl,
							start:
								newProjectData.start.length > 0
									? new Date(newProjectData.start).toISOString()
									: new Date().toISOString(),
							end:
								newProjectData.end.length > 0
									? new Date(newProjectData.end).toISOString()
									: new Date().toISOString(),
							withoutFinance: !financeActive,
							withMatterport: matterportActive,
						});

						setWidthEnregistre(150);
						setHeightEnregistre(130);
						setOpacityEnregistre(1);
						setTimeout(() => {
							setWidthEnregistre(0);
							setHeightEnregistre(0);
							setOpacityEnregistre(0);
						}, 1000);
					},
					'file'
				);
				setImgFile(null);
				setProjectData(newProjectData);
			} else {
				await firestore.doc(`projects/${projectId}`).update({
					...newProjectData,
					start:
						newProjectData.start.length > 0
							? new Date(newProjectData.start).toISOString()
							: new Date().toISOString(),
					end:
						newProjectData.end.length > 0
							? new Date(newProjectData.end).toISOString()
							: new Date().toISOString(),
					tag: newTag ? newTag : newProjectData.name,
					withoutFinance: !financeActive,
					withMatterport: matterportActive,
				});
				setProjectData(newProjectData);
				setWidthEnregistre(150);
				setHeightEnregistre(130);
				setOpacityEnregistre(1);
				setTimeout(() => {
					setWidthEnregistre(0);
					setHeightEnregistre(0);
					setOpacityEnregistre(0);
				}, 1000);
			}
			if (newProjectData.name !== projectData.name || newTag !== tag) {
				firestore
					.collection(`projects/${projectId}/accounts`)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach(async (documentSnapshot) => {
							const uid = documentSnapshot.data().uid;
							const type = documentSnapshot.data().type;
							const agency = (await firestore.doc(`accounts/${uid}`).get()).data().agency;
							await firestore
								.doc(
									type === 'collaborators'
										? `agencies/${agency}/collaborators/${uid}/projects/${projectId}`
										: `${type}/${uid}/projects/${projectId}`
								)
								.update({
									name: newProjectData.name,
									tag: newTag ? newTag : newProjectData.name,
								});
						});
					});
				await firestore.doc(`agencies/${agency}/projects/${projectId}`).update({
					name: newProjectData.name,
					tag: newTag ? newTag : newProjectData.name,
				});
				setTag(newTag ? newTag : newProjectData.name);
			}
		}
	};

	return (
		<div className={'informationsPage'}>
			<div className={'innerParametresProjet'}>
				<div
					className={'enregistre'}
					style={{ width: widthEnregistre, height: heightEnregistre, opacity: opacityEnregistre }}>
					<img src={checkBlanc} alt="" className={'checkBlanc'} />
					<p className={'textEnregistre'}>{t('common.saved')}</p>
				</div>

				<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: -30 }}>
					<button
						className={'cancelButton'}
						onClick={() => {
							setNewProjectData(projectData);
						}}>
						<p>{t('common.cancel')}</p>
					</button>
					<button
						className={'enregistrerButton'}
						disabled={
							(projectData === newProjectData &&
								newTag === tag &&
								!financeActive === projectData.withoutFinance &&
								matterportActive === projectData.withMatterport) ||
							newProjectData?.name?.length < 1
						}
						onClick={handleUpdateProject}>
						<p>{t('common.update')}</p>
					</button>
				</div>

				<div style={{ display: 'flex', marginTop: 20 }}>
					<div>
						<p className={'designantInput'} style={{ marginTop: 38, width: 260 }}>
							{t('project_settings.project_picture')}
						</p>
						<p
							className={'designantInput'}
							style={{
								marginTop: 10,
								width: 200,
								opacity: 0.7,
								fontWeight: 500,
							}}>
							{t('project_settings.info_project_picture')}
						</p>
					</div>

					<div className={'widgetProjet'} style={{ marginLeft: 0 }}>
						{newProjectData && newProjectData.imgUrl ? (
							<div>
								<label htmlFor={'input'}>
									<img src={newProjectData.imgUrl} alt={'img'} className={'photoProjet'} />
								</label>
								<input type="file" id="input" accept="image/*,.heic, .heif" onChange={imageHandler} />
							</div>
						) : (
							<div className={'photoProjet'} style={{ backgroundColor: '#F8F8F8' }} />
						)}

						<div>
							<p className={'nomProjet'}>
								{newProjectData ? newProjectData.name : t('project_settings.project_name')}
							</p>

							<div style={{ display: 'flex' }}>
								<div className={'bar'} />
								<p className={'percentage'}>78 %</p>
							</div>

							<div className={'backBar'} style={{ marginTop: 8 }}>
								<div className={'frontBar'} style={{ width: 170, backgroundColor: '#4867EC' }} />
							</div>

							<div className={'backBar'}>
								<div className={'frontBar'} style={{ width: 70, backgroundColor: '#4867EC' }} />
							</div>

							<div className={'backBar'}>
								<div className={'frontBar'} style={{ width: 130, backgroundColor: '#328536' }} />
							</div>

							<div className={'backBar'} style={{ marginBottom: 5 }}>
								<div className={'frontBar'} style={{ width: 100, backgroundColor: '#328536' }} />
							</div>
						</div>
					</div>
				</div>

				<div>
					<div className={'longBar'} style={{ marginTop: 30, marginBottom: 15 }} />
				</div>

				<div style={{ display: 'flex' }}>
					<div style={{ width: 260 }}>
						<p className={'designantInput'} style={{ marginTop: 30 }}>
							{t('project_settings.project_name')}
						</p>
					</div>
					<div>
						<div style={{ display: 'flex' }}>
							<input
								type="text"
								placeholder={t('project_settings.project_name')}
								id={'name'}
								value={newProjectData && newProjectData.name}
								onChange={handleChange}
							/>
						</div>
					</div>

					{newProjectData && newProjectData.name?.length < 1 ? (
						<div
							className={'backgroundColor1'}
							style={{
								width: 7,
								height: 7,
								marginLeft: 10,
								borderRadius: 20,
								marginTop: 35,
								backgroundColor: '#5e74d0',
							}}
						/>
					) : null}
				</div>

				<div>
					<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />
				</div>

				<div style={{ display: 'flex' }}>
					<p className={'designantInput'} style={{ marginTop: 30, width: 260 }}>
						{t('project_settings.ref_project')}
					</p>
					<div>
						<div style={{ display: 'flex' }}>
							<input
								type="text"
								placeholder={t('project_settings.placeholder_ref')}
								value={newTag}
								onChange={(event) => setNewTag(event.target.value)}
							/>
						</div>
						<p
							className={'designantInput'}
							style={{
								marginTop: -5,
								fontWeight: 500,
								opacity: 0.6,
								fontSize: 13,
							}}>
							{t('project_settings.infos_ref_project_1')} <br />{' '}
							{t('project_settings.infos_ref_project_2')}
						</p>
					</div>
				</div>

				<div>
					<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />
				</div>

				<div style={{ display: 'flex' }}>
					<p className={'designantInput'} style={{ marginTop: 30, width: 260 }}>
						{t('project_settings.name_project_owner')}
					</p>
					<input
						type="text"
						placeholder={'Louis Durand'}
						id={'clientName'}
						value={newProjectData && newProjectData.clientName}
						onChange={handleChange}
					/>
				</div>

				<div>
					<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />
				</div>

				<div style={{ display: 'flex' }}>
					<p className={'designantInput'} style={{ marginTop: 30, width: 260 }}>
						{t('project_settings.project_address')}
					</p>
					<input
						type="text"
						placeholder={t('project_settings.placeholder_address')}
						id={'address'}
						value={newProjectData && newProjectData.address}
						onChange={handleChange}
					/>
				</div>

				<div>
					<div className={'longBar'} style={{ marginTop: 15, marginBottom: 15 }} />
				</div>

				<div style={{ display: 'flex' }}>
					<p className={'designantInput'} style={{ marginTop: 30 }}>
						{t('project_settings.visible_finances')}
					</p>
					<div
						style={{
							width: 150,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: 125,
						}}>
						<div
							className={'choiceAcces'}
							style={{ backgroundColor: !financeActive ? '#eeeeee' : '#64b651' }}
							onClick={async () => {
								setFinanceActive(!financeActive);
							}}>
							<div className={'buttonChoice'} style={{ marginLeft: !financeActive ? 3 : 21 }} />
						</div>
					</div>
				</div>

				<div>
					<div className={'longBar'} style={{ marginTop: 30, marginBottom: 15 }} />
				</div>

				<div style={{ display: 'flex' }}>
					<p className={'designantInput'} style={{ marginTop: 30 }}>
						{t('project_settings.integration_matterport')}
					</p>
					<div
						style={{
							width: 150,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginLeft: 93,
						}}>
						<div
							className={'choiceAcces'}
							style={{ backgroundColor: !matterportActive ? '#eeeeee' : '#64b651' }}
							onClick={async () => {
								setMatterportActive(!matterportActive);
							}}>
							<div className={'buttonChoice'} style={{ marginLeft: !matterportActive ? 3 : 21 }} />
						</div>
					</div>
				</div>

				<div>
					<div className={'longBar'} style={{ marginTop: 30, marginBottom: 30 }} />
				</div>

				<div style={{ display: 'flex' }}>
					<p className={'designantInput'} style={{ marginTop: 10, width: 245 }}>
						{t('project_settings.project_status')}
					</p>
					<div
						className={
							newProjectData && newProjectData.active ? 'statutProjetActuel' : 'statutProjetNonChoisi'
						}
						onClick={() => {
							if (newProjectData && !newProjectData.active) {
								setModalProjetEnCours(true);
							}
						}}>
						<p>{t('project_settings.ongoing_project')}</p>
					</div>

					<div
						className={
							newProjectData && !newProjectData.active ? 'statutProjetActuel' : 'statutProjetNonChoisi'
						}
						onClick={() => {
							if (newProjectData && newProjectData.active) {
								setModalProjetAcheve(true);
							}
						}}>
						<p>{t('project_settings.achieved_project')}</p>
					</div>
				</div>
			</div>

			<Modal
				isOpen={modalConfirmationSuppressionProjet}
				className={'modalConfirmationSuppressionProjet'}
				overlayClassName="OverlayModal"
				closeTimeoutMS={300}>
				<img
					src={crossIcon}
					alt="crossIcon"
					className={'crossIcon'}
					onClick={() => {
						setModalConfirmationSuppressionProjet(!suppresionLoading && false);
					}}
				/>

				{motDePasseSuppression ? (
					<div>
						<p className={'titleModal'}>{t('modal_delete_project.enter_password')}</p>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
								flexDirection: 'column',
							}}>
							<p
								style={{
									marginTop: 10,
									marginLeft: 30,
									marginRight: 30,
									fontSize: 13,
									fontWeight: 500,
									color: '#ababab',
								}}>
								{t('modal_delete_project.modal_delete_project')}
							</p>
							<img
								style={{
									width: 13,
									objectFit: 'contain',
									opacity: 0.4,
								}}
								src={LockIcon}
								alt="LockIcon"
							/>
							<input
								type="password"
								className={'inputModifNomProjet'}
								placeholder={t('common.password')}
								value={authPassword}
								onChange={(e) => setAuthPassword(e.target.value)}
							/>
							<p>{error && t('modal_delete_project.incorrect_password')}</p>
						</div>
					</div>
				) : (
					<div>
						<p className={'titleModal'}>{t('modal_delete_project.want_delete_project')}</p>

						<p className={'subtitleModal'}>{t('modal_delete_project.important_infos')}</p>
						<div className={'barSubtitle'} style={{ width: 182 }} />

						<div style={{ marginLeft: 30, marginTop: 20 }}>
							<div style={{ display: 'flex' }}>
								<img src={deleteIcon} alt="choice" className={'choiceOptions'} />
								<p className={'textOption'}>{t('modal_delete_project.intel_will_be_delete')}</p>
							</div>
							<div style={{ display: 'flex' }}>
								<img src={deleteIcon} alt="choice" className={'choiceOptions'} />
								<p className={'textOption'}>{t('modal_delete_project.unrecoverable_intels')}</p>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								flex: 1,
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: 20,
							}}>
							<p style={{ fontSize: 13, marginTop: 5 }}>{t('modal_delete_project.this_action')}</p>
							<p
								style={{
									fontSize: 13,
									marginTop: 4,
									marginLeft: 5,
									fontWeight: 600,
								}}>
								{t('modal_delete_project.not')}
							</p>
							<p style={{ fontSize: 13, marginLeft: 5, marginTop: 5 }}>
								{t('modal_delete_project.reversible')}
							</p>
						</div>
					</div>
				)}

				{motDePasseSuppression ? (
					!suppresionLoading ? (
						<div style={{ display: 'flex', marginTop: -10 }} className={'buttonGroup'}>
							<div className={'jeConfirmeButton'} onClick={() => deleteProject()}>
								<p className={'textJeConfirme'}>{t('modal_delete_project.delete_the_project')}</p>
							</div>
							<img src={checkBleu} alt="choice" className={'choice'} />
						</div>
					) : (
						<div style={{ display: 'flex', marginTop: -10 }} className={'buttonGroup'}>
							<div className={'jeConfirmeButton'}>
								<p className={'textJeConfirme'}>{t('modal_delete_project.dont_reload_the_page')}</p>
							</div>
							<img src={checkBleu} alt="choice" className={'choice'} />
						</div>
					)
				) : (
					<div style={{ display: 'flex', marginTop: 10 }} className={'buttonGroup'}>
						<div className={'jeConfirmeButton'} onClick={() => setMotDePasseSuppression(true)}>
							<p className={'textJeConfirme'}>{t('common.save')}</p>
						</div>
						<img src={checkBleu} alt="choice" className={'choice'} />
					</div>
				)}
			</Modal>

			<Modal
				isOpen={modalProjetAcheve}
				className={'modalStatut'}
				overlayClassName="OverlayModal"
				closeTimeoutMS={300}>
				<img
					src={crossIcon}
					alt="crossIcon"
					className={'crossIcon'}
					onClick={() => {
						setModalProjetAcheve(false);
					}}
				/>

				<p className={'titleModal'}>{t('modal_achieved_project.title')}</p>

				<p className={'subtitleModal'}>{t('modal_achieved_project.important_infos')}</p>
				<div className={'barSubtitle'} style={{ width: 182 }} />

				<div style={{ marginLeft: 30, marginTop: 20 }}>
					<div style={{ display: 'flex' }}>
						<img src={checkBleu} alt="choice" className={'choiceOptions'} />
						<p className={'textOption'}>{t('modal_achieved_project.project_still_for_clients')}</p>
					</div>
					<div style={{ display: 'flex' }}>
						<img src={checkBleu} alt="choice" className={'choiceOptions'} />
						<p className={'textOption'}>{t('modal_achieved_project.project_still_for_collab')}</p>
					</div>
					<div style={{ display: 'flex' }}>
						<img src={deleteIcon} alt="choice" className={'choiceOptions'} />
						<p className={'textOption'}>{t('modal_achieved_project.notifs_disabled')}</p>
					</div>
					<div style={{ display: 'flex' }}>
						<img src={deleteIcon} alt="choice" className={'choiceOptions'} />
						<p className={'textOption'}>
							{t('modal_achieved_project.project_not_longer_appear_in_ongoing')}
						</p>
					</div>
				</div>

				<div
					style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
					<p style={{ fontSize: 13, marginTop: 5 }}>{t('modal_achieved_project.this_action')}</p>
					<p
						style={{
							fontSize: 13,
							marginTop: 4,
							marginLeft: 5,
							fontWeight: 600,
						}}>
						{t('modal_delete_project.reversible')}
					</p>
					<p style={{ fontSize: 13, marginLeft: 5, marginTop: 5 }}>
						{t('modal_achieved_project.without_limit')}
					</p>
				</div>

				<div
					style={{ display: 'flex', marginTop: 10 }}
					className={'buttonGroup'}
					onClick={async () => {
						await firestore.doc(`projects/${projectId}`).update({ active: false });
						firestore
							.collection(`projects/${projectId}/accounts`)
							.get()
							.then((querySnapshot) => {
								if (querySnapshot) {
									querySnapshot.forEach(async (documentSnapshot) => {
										if (documentSnapshot && documentSnapshot.exists) {
											const type = documentSnapshot.data().type;
											const uid = documentSnapshot.data().uid;
											if (type === 'collaborators') {
												await firestore
													.doc(
														`agencies/${documentSnapshot.data().agency
														}/collaborators/${uid}/projects/${projectId}`
													)
													.update({ active: false });
											} else {
												await firestore
													.doc(`${type}/${uid}/projects/${projectId}`)
													.update({ active: false });
											}
										}
									});
								}
							});
						await firestore.doc(`projects/${projectId}`).update({ finishDate: new Date().toISOString() });
						setNewProjectData({ ...newProjectData, active: false });
						setModalProjetAcheve(false);
					}}>
					<div className={'jeConfirmeButton'}>
						<p className={'textJeConfirme'}>{t('common.i_confirm')}</p>
					</div>
					<img src={checkBleu} alt="choice" className={'choice'} />
				</div>
			</Modal>

			<Modal
				isOpen={modalProjetEnCours}
				className={'modalStatut'}
				overlayClassName="OverlayModal"
				closeTimeoutMS={300}>
				<img
					src={crossIcon}
					alt="crossIcon"
					className={'crossIcon'}
					onClick={() => {
						setModalProjetEnCours(false);
					}}
				/>

				<p className={'titleModal'}>{t('modal_achieved_project.switch_to_ongoing')}</p>

				<p className={'subtitleModal'}>{t('modal_achieved_project.important_infos')}</p>
				<div className={'barSubtitle'} style={{ width: 182 }} />

				<div style={{ marginLeft: 30, marginTop: 20 }}>
					<div style={{ display: 'flex' }}>
						<img src={checkBleu} alt="choice" className={'choiceOptions'} />
						<p className={'textOption'}>{t('modal_achieved_project.project_still_accessible_clients')}</p>
					</div>
					<div style={{ display: 'flex' }}>
						<img src={checkBleu} alt="choice" className={'choiceOptions'} />
						<p className={'textOption'}>{t('modal_achieved_project.project_still_accessible_collab')}</p>
					</div>
					<div style={{ display: 'flex' }}>
						<img src={checkBleu} alt="choice" className={'choiceOptions'} />
						<p className={'textOption'}>{t('modal_achieved_project.notifs_activated')}</p>
					</div>
					<div style={{ display: 'flex' }}>
						<img src={checkBleu} alt="choice" className={'choiceOptions'} />
						<p className={'textOption'}>{t('modal_achieved_project.project_in_ongoings')}</p>
					</div>
				</div>

				<div
					style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
					<p style={{ fontSize: 13, marginTop: 5 }}>{t('modal_achieved_project.this_action')}</p>
					<p
						style={{
							fontSize: 13,
							marginTop: 4,
							marginLeft: 5,
							fontWeight: 600,
						}}>
						{t('modal_achieved_project.reversible')}
					</p>
					<p style={{ fontSize: 13, marginLeft: 5, marginTop: 5 }}>
						{t('modal_achieved_project.without_limit')}
					</p>
				</div>

				<div style={{ display: 'flex', marginTop: 10 }} className={'buttonGroup'}>
					<div
						className={'jeConfirmeButton'}
						onClick={async () => {
							await firestore.doc(`projects/${projectId}`).update({ active: true });
							firestore
								.collection(`projects/${projectId}/accounts`)
								.get()
								.then((querySnapshot) => {
									if (querySnapshot) {
										querySnapshot.forEach(async (documentSnapshot) => {
											if (documentSnapshot && documentSnapshot.exists) {
												const type = documentSnapshot.data().type;
												const uid = documentSnapshot.data().uid;
												if (type === 'collaborators') {
													await firestore
														.doc(
															`agencies/${documentSnapshot.data().agency
															}/collaborators/${uid}/projects/${projectId}`
														)
														.update({ active: true });
												} else {
													await firestore
														.doc(`${type}/${uid}/projects/${projectId}`)
														.update({ active: true });
												}
											}
										});
									}
								});
							await firestore.doc(`projects/${projectId}`).update({ finishDate: '' });
							setNewProjectData({ ...newProjectData, active: true });
							setModalProjetEnCours(false);
						}}>
						<p className={'textJeConfirme'}>{t('common.i_confirm')}</p>
					</div>
					<img src={checkBleu} alt="choice" className={'choice'} />
				</div>
			</Modal>
		</div>
	);
}
